<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Customer Vehicles</h5>
        </div>
        <div class="card-body">
            <section>
                <form class="needs-validation">
                    <div class="col-8">
                        <div class="row">
                            <div class="col form-group">
                                <label for="vin" class="mr-2">
                                    VIN:</label>
                                <input #search type="text" class="form-control" id="vin" placeholder="Search Vin" (keyup)="onSearchVin(search.value)">
                            </div>
                            <div class="col form-group">
                                <label for="registration" class="mr-2">
                                    REGISTRATION:</label>
                                <input #search2 type="text" id="registration" class="form-control" placeholder="Search Registration" (keyup)="onSearchReg(search2.value)">
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <section>
                <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                    <div class="table-responsive">
                        <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
