<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h5>Perform User Account Check</h5>
      <small>This section allows you to check if a customer is already registered.</small>
    </div>
    <div class="card-body">
      <section>
        <form class="needs-validation">
          <div class="col-8">
            <div class="row">
              <div class="col form-group">
                <label for="idNumber" class="mr-2">
                  ID Number:</label>
                <input type="text" name="searchId" [(ngModel)]="searchParam" [ngModelOptions]="{standalone: true}"
                  id="idNumber" class="form-control">
              </div>
              <div class="m-t-20">
                <button (click)="searchUser(searchParam)" class="btn btn-primary m-r-10" type="button">Search</button>
                <!-- <button class="btn btn-secondary" type="button">Buy Now</button> -->
              </div>
            </div>
            <!-- <div class="pull-right"> -->
            <!-- </div> -->
          </div>
        </form>
      </section>
      <section *ngIf="resultFound">
        <h3>User account found</h3>
        <p><strong>Name: </strong>{{userDetails.name | titlecase}} {{userDetails.surname | titlecase}}</p>
        <p><strong>ID Number: </strong>{{userDetails.id_number}}</p>
        <button (click)="useAccount()" class="btn btn-primary m-r-10" type="button">Use this account</button>
      </section>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h5>Needs Analysis Customer Registration</h5>
    </div>
    <div class="card-body">
      <div class="col-8">
        <form [formGroup]="userForm" [hidden]="hideUserForm">
          <section>
            <h4>Personal Details</h4>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label>Name</mat-label>
                <input formControlName="name" matInput placeholder="First Name">
                <mat-error *ngIf="userFormErrors.name">{{userFormErrors?.name}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label>Surname</mat-label>
                <input formControlName="surname" matInput placeholder="Last Name">
                <mat-error *ngIf="userFormErrors.surname">{{userFormErrors?.surname}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Contact</mat-label>
                <input formControlName="contact" matInput placeholder="Contact">
                <mat-error *ngIf="userFormErrors.contact">{{userFormErrors?.contact}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Email</mat-label>
                <input formControlName="email" matInput placeholder="Email">
                <mat-error *ngIf="userFormErrors.email">{{userFormErrors?.email}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  ID Number</mat-label>
                <input formControlName="id_number" matInput placeholder="">
                <mat-error *ngIf="userFormErrors.id_number">{{userFormErrors?.id_number}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Residential Address</h4>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Street</mat-label>
                <input formControlName="street" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.street">{{userFormErrors?.street}}</mat-error> -->
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Building</mat-label>
                <input formControlName="building" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.building">{{userFormErrors?.building}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Suburb</mat-label>
                <input formControlName="suburb" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.suburb">{{userFormErrors?.suburb}}</mat-error> -->
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  City</mat-label>
                <input formControlName="city" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.city">{{userFormErrors?.city}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Province</mat-label>
                <mat-select formControlName="province">
                  <mat-option *ngFor="let province of provinces;" [value]="province.name">
                    {{province.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Postal Code</mat-label>
                <input formControlName="postal_code" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.province">{{userFormErrors?.province}}</mat-error> -->
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Work Address</h4>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Street</mat-label>
                <input formControlName="work_street" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.work_street">{{userFormErrors?.work_street}}</mat-error> -->
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Building</mat-label>
                <input formControlName="work_building" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.work_building">{{userFormErrors?.work_building}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Suburb</mat-label>
                <input formControlName="work_suburb" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.work_suburb">{{userFormErrors?.work_suburb}}</mat-error> -->
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  City</mat-label>
                <input formControlName="work_city" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.work_city">{{userFormErrors?.work_city}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Province</mat-label>
                <mat-select formControlName="work_province">
                  <mat-option *ngFor="let province of provinces;" [value]="province.name">
                    {{province.name}}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="userFormErrors.work_province">{{userFormErrors?.work_province}}</mat-error> -->
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Postal Code</mat-label>
                <input formControlName="work_p_code" matInput placeholder="">
                <!-- <mat-error *ngIf="userFormErrors.work_p_code">{{userFormErrors?.work_p_code}}</mat-error> -->
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Income</h4>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Before Deductions</mat-label>
                <input formControlName="gross_income" matInput placeholder="">
                <mat-error *ngIf="userFormErrors.gross_income">{{userFormErrors?.gross_income}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  After Deductions</mat-label>
                <input formControlName="net_income" matInput placeholder="">
                <mat-error *ngIf="userFormErrors.net_income">{{userFormErrors?.net_income}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Designation</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Position</mat-label>
                <mat-select formControlName="designation">
                  <mat-option *ngFor="let designation of designations; let i = index" [value]="designation.id">
                    {{designation.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userFormErrors.designation">{{userFormErrors?.designation}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>User Device</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Device Type</mat-label>
                <mat-select formControlName="device_type">
                  <mat-option *ngFor="let device of devices; let i = index" [value]="device.id">
                    {{device.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userFormErrors.device_type">{{userFormErrors?.device_type}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Create Login Password</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Password</mat-label>
                <input readonly matInput formControlName="password" type="password" value="1234">
                <mat-error *ngIf="userFormErrors.password">{{userFormErrors?.password}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Confirm Password</mat-label>
                <input readonly formControlName="verify_password" matInput type="password" value="1234">
                <mat-error *ngIf="userFormErrors.verify_password">{{userFormErrors?.verify_password}}</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>
        <form [formGroup]="needsForm" class="needs-validation">
          <section>
            <h4>Vehicle Of Interest</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Stock List Vehicle</mat-label>
                <mat-select id="car_exists" [value]="carExistsValue" (valueChange)="carExistsChangeEvent($event)">
                  <mat-option *ngFor="let item of yesNo" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.make">{{needsFormErrors?.make}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group" [hidden]="!carExists">
                <mat-label class="mr-2">
                  User Car Or New Car?</mat-label>
                <mat-select #newOrUsed id="used_or_new" (valueChange)="usedOrNewChangeEvent($event)">
                  <mat-option *ngFor="let item of usedNewDropDown" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.model">{{needsFormErrors?.model}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row" [hidden]="!carExists">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Vehicle</mat-label>
                <mat-select id="cars" (valueChange)="vehicleSelected($event)">
                  <input style="height: 40px;" #newCar matInput [placeholder]="vehicleSearchPlaceholder" />
                  <mat-option *ngFor="let item of vehicleList" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.make">{{needsFormErrors?.make}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section [hidden]="carExists">
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Brand/Make</mat-label>
                <input formControlName="make" id="make" matInput>
                <mat-error *ngIf="needsFormErrors.make">{{needsFormErrors?.make}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Model</mat-label>
                <input formControlName="model" id="vehicle_model" matInput>
                <mat-error *ngIf="needsFormErrors.model">{{needsFormErrors?.model}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Color</mat-label>
                <input formControlName="colour" matInput>
                <mat-error *ngIf="needsFormErrors.colour">{{needsFormErrors?.colour}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Color Option 2</mat-label>
                <input formControlName="colour2" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.colour2">{{needsFormErrors?.colour2}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Color Option 3</mat-label>
                <input formControlName="colour3" matInput>
                <mat-error *ngIf="needsFormErrors.colour3">{{needsFormErrors?.colour3}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Type</mat-label>
                <mat-select formControlName="manufacture_type" id="manufacture_type">
                  <mat-option *ngFor="let item of vehicleType" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.manufacture_type">{{needsFormErrors?.manufacture_type}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section [hidden]="carExists">
            <h4>Key Features</h4>
            <div class="row">
              <div class="col-md">
                <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <mat-checkbox formControlName="park_distance">Park Distance Control</mat-checkbox>
                  <mat-error *ngIf="needsFormErrors.park_distance">{{needsFormErrors?.park_distance}}</mat-error>
                  <mat-checkbox formControlName="sports">Sports Package</mat-checkbox>
                  <mat-error *ngIf="needsFormErrors.sports">{{needsFormErrors?.sports}}</mat-error>
                  <mat-checkbox formControlName="sunroof">Sunroof</mat-checkbox>
                  <mat-error *ngIf="needsFormErrors.sunroof">{{needsFormErrors?.sunroof}}</mat-error>
                  <mat-checkbox formControlName="smash_protect">Smash & Grab</mat-checkbox>
                  <mat-error *ngIf="needsFormErrors.smash_protect">{{needsFormErrors?.smash_protect}}</mat-error>
                </div>
              </div>
            </div>
          </section>
          <section [hidden]="carExists">
            <h4>Extra Features<small> (Please enter a new feature on a new line.)</small></h4>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  Extra Features<small> (Please enter a new feature on a new line.)</small></mat-label>
                <textarea formControlName="features" rows="10" cols="60" matInput></textarea>
                <mat-error *ngIf="needsFormErrors.features">{{needsFormErrors?.features}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Engine Size</mat-label>
                <mat-select formControlName="engine" id="engine">
                  <mat-option *ngFor="let item of engineSize" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.engine">{{needsFormErrors?.engine}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Transmission</mat-label>
                <mat-select formControlName="transmission" id="transmission">
                  <mat-option *ngFor="let item of transmission" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.transmission">{{needsFormErrors?.transmission}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Fuel Type</mat-label>
                <mat-select formControlName="fuel" id="fuel">
                  <mat-option *ngFor="let item of fuelType" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.fuel">{{needsFormErrors?.fuel}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Deposit & Installment</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Do you have a deposit?</mat-label>
                <mat-select formControlName="deposit" id="deposit" (valueChange)="depositChangeEvent($event)">
                  <mat-option *ngFor="let item of yesNo" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.deposit">{{needsFormErrors?.deposit}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group" [hidden]="!showDepositInfo">
                <mat-label class="mr-2">
                  Deposit Amount</mat-label>
                <input formControlName="deposit_amnt" matInput>
                <mat-error *ngIf="needsFormErrors.deposit_amnt">{{needsFormErrors?.deposit_amnt}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Desired Installment Amount</mat-label>
                <input formControlName="installment" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.installment">{{needsFormErrors?.installment}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Trade In</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Trade in current vehicle?</mat-label>
                <mat-select formControlName="trading" id="trading" (valueChange)="tradeInChangeEvent($event)">
                  <mat-option *ngFor="let item of yesNo" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.trading">{{needsFormErrors?.trading}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group" [hidden]="!showTradeInForm">
                <mat-label class="mr-2">
                  Make & Model</mat-label>
                <input formControlName="vehicle_trd" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.vehicle_trd">{{needsFormErrors?.vehicle_trd}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 form-group" [hidden]="!showTradeInForm">
                <mat-label class="mr-2">
                  Year</mat-label>
                <input formControlName="trade_year" matInput placeholder="" type="number">
                <mat-error *ngIf="needsFormErrors.trade_year">{{needsFormErrors?.trade_year}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group" [hidden]="!showTradeInForm">
                <mat-label class="mr-2">
                  Vin</mat-label>
                <input formControlName="trade_vin" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.trade_vin">{{needsFormErrors?.trade_vin}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 form-group" [hidden]="!showTradeInForm">
                <mat-label class="mr-2">
                  Engine Number</mat-label>
                <input formControlName="trade_engine_no" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.trade_engine_no">{{needsFormErrors?.trade_engine_no}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group" [hidden]="!showTradeInForm">
                <mat-label class="mr-2">
                  Engine Size</mat-label>
                <input formControlName="trade_engine_size" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.trade_engine_size">{{needsFormErrors?.trade_engine_size}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row" [hidden]="!showTradeInForm">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Fuel Type</mat-label>
                <mat-select formControlName="trade_fuel_type" id="trade_fuel_type">
                  <mat-option *ngFor="let item of fuelType" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.trade_fuel_type">{{needsFormErrors?.trade_fuel_type}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Transmission</mat-label>
                <mat-select formControlName="trade_transmission" id="trade_transmission">
                  <mat-option *ngFor="let item of transmission" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.trade_transmission">{{needsFormErrors?.trade_transmission}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row" [hidden]="!showTradeInForm">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Colour</mat-label>
                <input formControlName="trade_colour" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.trade_colour">{{needsFormErrors?.trade_colour}}</mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Body/Model Type</mat-label>
                <mat-select formControlName="trade_type" id="trade_type">
                  <mat-option *ngFor="let item of vehicleShape" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="needsFormErrors.trade_type">{{needsFormErrors?.trade_type}}</mat-error>
              </mat-form-field>
            </div>
            <div class="row" [hidden]="!showTradeInForm">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Current Kilometers</mat-label>
                <input formControlName="trade_kilos" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.trade_kilos">{{needsFormErrors?.trade_kilos}}</mat-error>
              </mat-form-field>
              <!-- <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Financed By</mat-label>
                <input formControlName="trade_finance" matInput placeholder="">
                <mat-error *ngIf="needsFormErrors.trade_finance">{{needsFormErrors?.trade_finance}}</mat-error>
              </mat-form-field> -->
              <!-- TODO: new from here  -->
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Is it financed?</mat-label>
                <mat-select id="trade_paidOff" (valueChange)="financedByChangeEvent($event)">
                  <mat-option *ngFor="let item of ['Financed','Paid off']" [value]="item">{{item}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-6 form-group" [hidden]="!showFinancedBy">
                <mat-label class="mr-2">
                  Financed By</mat-label>
                <input formControlName="trade_finance" matInput>
                <mat-error *ngIf="needsFormErrors.trade_finance">{{needsFormErrors?.trade_finance}}</mat-error>
              </mat-form-field>
            </div>
            <!-- new above here  -->
            <div class="row" [hidden]="!showTradeInForm">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  <h4>Notes on trade in vehicle</h4>
                </mat-label>
                <textarea formControlName="trade_notes" rows="5" cols="60" matInput></textarea>
                <mat-error *ngIf="needsFormErrors.trade_notes">{{needsFormErrors?.trade_notes}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Notes <small>(2nd Vehicle Option)</small></h4>
            <div class="row">
              <mat-form-field class="col form-group">
                <mat-label class="mr-2">
                  <h4>Notes <small>(2nd Vehicle Option)</small></h4>
                </mat-label>
                <textarea formControlName="trade_notes" rows="5" cols="60" matInput></textarea>
                <mat-error *ngIf="needsFormErrors.trade_notes">{{needsFormErrors?.trade_notes}}</mat-error>
              </mat-form-field>
            </div>
          </section>
          <section>
            <h4>Set Appointment</h4>
            <div class="row">
              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Next Appointment Date:</mat-label>
                <input matInput [matDatepicker]="datePicker" formControlName="next_appointment" />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="needsFormErrors.next_appointment">{{needsFormErrors?.next_appointment}}</mat-error>
              </mat-form-field>

              <mat-form-field class="col-md-6 form-group">
                <mat-label class="mr-2">
                  Next Appointment Time:</mat-label>
                <input matInput type="time" formControlName="app_time" />
                <mat-error *ngIf="needsFormErrors.app_time">{{needsFormErrors?.app_time}}</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>
      </div>
      <div class="pull-right">
        <button type="button" (click)="registerCustomer()" class="btn btn-primary">Register Customer</button>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->