import { Validators } from '@angular/forms';
import { mobileNumberValidator } from '../utils/validators';

export interface INeedsAnalyses {
    id?: number;
    vehicle_id?: string;
    make?: string;
    model?: string;
    colour?: string;
    colour2?: string;
    colour3?: string;
    manufacture_type?: string;
    park_distance?: string;
    sports?: string;
    sunroof?: string;
    smash_protect?: string;
    features?: string;
    engine?: string;
    transmission?: string;
    fuel?: string;
    deposit?: string;
    deposit_amnt?: string;
    installment?: string;
    trading?: string;
    vehicle_trd?: string;
    trade_year?: string;
    trade_vin?: string;
    trade_engine_no?: string;
    trade_engine_size?: string;
    trade_fuel_type?: string;
    trade_transmission?: string;
    trade_type?: string;
    trade_colour?: string;
    trade_kilos?: string;
    trade_finance?: string;
    trade_notes?: string;
    user?: string;
    user_idnum?: string;
    date?: Date;
    dealer_id?: string;
    deal_status?: string;
    allocation?: string;
    rating?: string;
    review?: string;
    notes?: string;
    next_appointment?: string;
    app_time?: string;
}

export class NeedsAnalysisFormError {
  // tslint?:disable
  allocation = '';
  app_time = '';
  colour = '';
  colour2 = '';
  colour3 = '';
  date = '';
  deal_status = '';
  dealer_id = '';
  deposit = '';
  deposit_amnt = '';
  engine = '';
  features = '';
  fuel = '';
  // id = '';
  installment = '';
  make = '';
  manufacture_type = '';
  model = '';
  next_appointment = '';
  notes = '';
  park_distance = '';
  rating = '';
  review = '';
  smash_protect = '';
  sports = '';
  sunroof = '';
  trade_colour = '';
  trade_engine_no = '';
  trade_engine_size = '';
  trade_finance = '';
  trade_fuel_type = '';
  trade_kilos = '';
  trade_notes = '';
  trade_transmission = '';
  trade_type = '';
  trade_vin = '';
  trade_year = '';
  trading = '';
  transmission = '';
  user = '';
  user_idnum = '';
  vehicle_id = '';
  vehicle_trd = '';
}

export class NeedsAnalysisUserFormErrors {
  add_vehicle = '';
  pro_pic = '';
  name = '';
  surname = '';
  password = '';
  verify_password = '';
  city = '';
  postal_code = '';
  contact = '';
  email = '';
  id_number = '';
  street = '';
  building = '';
  suburb = '';
  province = '';
  work_street = '';
  work_building = '';
  work_suburb = '';
  work_city = '';
  work_province = '';
  work_p_code = '';
  kin = '';
  kin_relation = '';
  kin_contact = '';
  gross_income = '';
  net_income = '';
  designation = '';
  device_type = '';
  practice_no = '';
  bank_name = '';
  bank_acc_no = '';
  bank_acc_type = '';
  bank_acc_holder = '';
  branch = '';
  branch_code = '';
  reg_fee = '';
  accepted_terms = '';
  signature = '';
  register_date = '';
  company = '';
  company_reg = '';
  company_tel = '';
  dealer_id = '';
  device = '';
  last_login = '';
  reg_status = '';
}
