import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DryService } from 'src/app/shared/service/dry.service';
import { AuthenticationService } from '../../../_services/authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  submitted = false;
  error = '';

  public loginForm: FormGroup;
  public registerForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dryService: DryService) {
    this.createLoginForm();
    this.createRegisterForm();
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
      this.dryService.presentToast(`You're already logged in.`);
    }
  }

  owlcarousel = [
    {
      title: "Welcome to The Doctors Club",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    console.log('DEPLOYED from bills.');
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
    })
  }

  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      username: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    // this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.dryService.presentToast(`Please fill in both fields.`);
      return;
    }

    this.loading = true;
    this.authenticationService.loginPromise(this.f.username.value, this.f.password.value)
      .then(() => {
        this.authenticationService.getDealerAccount()
          .then(() => {
            // get return url from route parameters or default to '/'
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard/default';
            this.authenticationService.getDealerAccount();
            this.router.navigate([returnUrl]);
            this.dryService.presentToast(`Login Successful. Welcome to the Doctors Club`);
          });

      }, err => {
        this.dryService.presentToast(`Incorect email or password.`);
        this.error = err;
        this.loading = false;

      })

  }

}
