import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NeedsAnalysisComponent } from './needs-analysis.component';
import { MyClientsComponent } from './my-clients/my-clients.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NeedsAnalysisRoutingModule } from './needs-analysis-routing.module';
import { AllClientsComponent } from './all-clients/all-clients.component';
import { NeedsAnalysisStatsTargetsComponent } from './needs-analysis-stats-targets/needs-analysis-stats-targets.component';
import { NeedsAnalysisViewTargetsComponent } from './needs-analysis-view-targets/needs-analysis-view-targets.component';
import { MatRadioModule } from '@angular/material/radio';
import { MaterialModule } from 'src/app/modules/material.module';
import { CustomersRoutingModule } from '../customers/customers-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';



@NgModule({
  declarations: [
    NeedsAnalysisComponent,
    MyClientsComponent,
    AllClientsComponent,
    NeedsAnalysisStatsTargetsComponent,
    NeedsAnalysisViewTargetsComponent],
    imports: [
        CommonModule,
        NgbModule,
        Ng2SmartTableModule,
        ReactiveFormsModule,
        NeedsAnalysisRoutingModule,
        ReactiveFormsModule,
        CustomersRoutingModule,
        FormsModule,
        MatRadioModule,
        MaterialModule,
        MatCheckboxModule,
        MatDatepickerModule
    ]
})
export class NeedsAnalysisModule { }
