<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <!-- <div class="card-header"> -->
            <!-- MOVE COMPONENT TODO: NEEDS ANALYSIS -->
            <!-- <h5>My Clients</h5> -->
        <!-- </div> -->
        <div class="card-body">
            <form class="needs-validation">
                    <div class="col-8">
                    <section>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2">
                                    Customer ID Number</label>
                                <input type="text" class="form-control" placeholder="Optional">
                            </div>
                            <div class="col-md-2 form-group">
                                <label for="validationCustom0" class="mr-2">
                                    Deal Status</label>
                                <Select type="text" class="form-control">
                                    <option value="" selected>All</option>
                                    <option value="">All</option>
                                </Select>
                            </div>
                            <div class="col-md-4 form-group">
                                <label for="validationCustom0" class="mr-2">
                                    Filter By Date</label>
                                <Select type="text" class="form-control">
                                    <option value="" selected>All</option>
                                    <option value="">All</option>
                                </Select>
                            </div>
                            <div class="m-b-10 m-l-15">
                                <button class="btn btn-primary" type="button">Search</button>
                                <button class="btn btn-primary m-l-10" type="button">Clear</button>
                            </div>
                        </div>
                                        </section>
                </div>
                    <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                        </div>
                    </div>
                </form>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->