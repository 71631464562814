import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsedCarsEnquiriesComponent } from './used-cars-enquiries/used-cars-enquiries.component';
import { UsedCarsComponent } from './used-cars/used-cars.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'used-cars',
        component: UsedCarsComponent,
        data: {
          title: "Used Cars",
          breadcrumb: "Used Cars"
        }
      },
      {
        path: 'used-cars-enquiries',
        component: UsedCarsEnquiriesComponent,
        data: {
          title: "Used Cars Enquiries",
          breadcrumb: "Used Cars Enquiries"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UsedCarsRoutingModule { }
