import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { any as any } from '../interfaces/any';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewCarEnquiriesService {

  private apiServer = environment.appEngineURL;
  // public token = JSON.parse(localStorage.getItem('currentUser')).token;
  public token = ''
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  }
  constructor(private httpClient: HttpClient) { }

  create(newCarEnquiries: any): Observable<any> {
    return this.httpClient.post<any>(this.apiServer + '/newCarEnquiries/', JSON.stringify(newCarEnquiries), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }  
  getById(id): Observable<any[]> {
    return this.httpClient.get<any[]>(this.apiServer + '/newCarEnquiries/' + id, this.httpOptions)
    .pipe(map((response: any) => response.json()));

    // return this.httpClient.get<UsedCarEnquiries>(this.apiServer + '/newCarEnquiriess/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    // )
  }

  getByDealerId(id): Observable<any> {
    return this.httpClient.get<any>(this.apiServer + '/newCarEnquiries/' + id, this.httpOptions)
   .pipe(map((response: any) => response.json()));
  }

  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.apiServer + '/newCarEnquiries/', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id, product): Observable<any> {
    return this.httpClient.put<any>(this.apiServer + '/newCarEnquiries/' + id, JSON.stringify(product), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    return this.httpClient.delete<any>(this.apiServer + '/newCarEnquiries/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  errorHandler(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}