import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';


// ************************ Mobile number validator *****************************//
export function mobileNumberValidator(digits: number = 13): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regEx = /^(\+27|\+270|0)?(6|7|8|9|1){1}[0-9]{1}[0-9]{7}$/;
    const validNumber = control.value && regEx.test(control.value.toString().trim());
    return !validNumber ? { mobileNumber: { value: control.value, required: digits } } : null;
  };
}

// ************************ RSA ID number validator *****************************//
export function rsaIdValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (ValidateIdNumber(control.value)) {
      return null;
    } else {
      return { rsaId: { value: control.value } };
    }
  };
}
export function ValidateIdNumber(idNumber) {
  const validNumber = idNumber && idNumber.toString().length === 13 && /^[0-9]+$/.test(idNumber);
  const d = idNumber && idNumber.toString().substring(0, 6) || '';
  const yy = d.substr(0, 2);
  const mm = d.substr(2, 2);
  const dd = d.substr(4, 2);
  const yyyy = (+yy < 90) ? '20' + yy : '19' + yy;
  const date = moment(yyyy + '-' + mm + '-' + dd, 'YYYY-MM-DD', true);
  return !(!date.isValid() || !validNumber);
}
