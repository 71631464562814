import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { QuoteRequestService } from 'src/app/shared/service/quoteRequest.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'app-get-a-quote',
  templateUrl: './get-a-quote.component.html',
  styleUrls: ['./get-a-quote.component.scss']
})
export class GetAQuoteComponent implements OnInit {

  public transactions: any

  constructor(private quoteService: QuoteRequestService) {
    // TODO: Change TransactionDB to My ClIENTS DB
    quoteService.getAll()
    .subscribe((data) => {
      this.transactions = new LocalDataSource(data);
    })
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      date: {
        name: 'amountaaaaa',
        title: '<i class="ion-document" title="YourAction"></i>'
      },
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      make: {
        title: 'Name', filter: false
      },
      mobile: {
        title: 'Contact', filter: false
      },
      vin: {
        title: 'ID Number', filter: false
      },
      designation: {
        title: 'Designation', filter: false,
        type: 'html',
      },
      // TODO: CHANGE COLUMNS TO ACTION BUTTONS
      delivery_status: {
        title: 'Action', filter: false
      },
      amount: {
        title: 'Action', filter: false
      }
    },
  };

  ngOnInit(): void {
  }

  onSearchStatus(query: string = '') {
    if (query === '') {
            
      this.transactions.setFilter([]);
      return ;
      
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false); 
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}
