import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NewCarsComponent } from './new-cars/new-cars.component';
import { NewCarsRoutingModule } from './new-cars-routing.module';
import { NewCarsEnquiriesComponent } from './new-cars-enquiries/new-cars-enquiries.component';



@NgModule({
  declarations: [NewCarsComponent, NewCarsEnquiriesComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    NewCarsRoutingModule
  ]
})
export class NewCarsModule { }
