import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DoctorAccountService } from 'src/app/shared/service/doctors.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { FormService } from '../../../_services/form.service';
import { mobileNumberValidator, rsaIdValidator } from '../../../shared/utils/validators';
import { DryService } from '../../../shared/service/dry.service';
import { TitleCasePipe } from '@angular/common';
import { DropDownConstants } from '../../../shared/utils/Constants';
import { VehicleService } from '../../../shared/service/vehicles.service';
import { Vehicle } from '../../../shared/interfaces/Vehicle';

@Component({
  selector: 'app-customer-register',
  templateUrl: './customer-register.component.html',
  styleUrls: ['./customer-register.component.scss']
})
export class CustomerRegisterComponent implements OnInit {
  public generalForm: FormGroup;
  public restrictionForm: FormGroup;
  public usageForm: FormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;

  provinces = [];
  designations = [];
  devices = [];
  public showVehicleDetails = false;
  public addMode!: boolean;
  public view!: boolean;
  public customerId!: string;
  public dealerId!: string;
  public vehicleMake = DropDownConstants.vehicleMake;
  public vehicleModel = [];
  public formErrors = {
    name: '',
    surname: '',
    password: '',
    verify_password: '',
    city: '',
    postal_code: '',
    contact: '',
    email: '',
    id_number: '',
    street: '',
    building: '',
    suburb: '',
    province: '',
    work_street: '',
    work_building: '',
    work_suburb: '',
    work_city: '',
    work_province: '',
    work_p_code: '',
    kin: '',
    kin_relation: '',
    kin_contact: '',
    gross_income: '',
    net_income: '',
    designation: '',
    device_type: ''
  };
  public hideFinanceRadioBtn = true;

  constructor(
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private doctorService: DoctorAccountService,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private titleCase: TitleCasePipe,
    private formService: FormService,
    private dryService: DryService
  ) {
    this.provinces = this.getProvinces();
    this.designations = this.getDesignation();
    this.devices = this.getDevices();
    this.customerId = this.route.snapshot.queryParams.customerId;
    const viewParam = this.route.snapshot.queryParams.view;
    this.dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    this.addMode = !this.customerId;
    this.view = viewParam;
    this.createGeneralForm();
    this.createRestrictionForm();
    this.createUsageForm();
  }

  toggle() {
    this.showVehicleDetails = !this.showVehicleDetails;
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  private get password() {
    const ctrl = this.generalForm.get('password');
    return ctrl ? ctrl.value : null;
  }
  private get verifyPassword() {
    const ctrl = this.generalForm.get('verify_password');
    return ctrl ? ctrl.value : null;
  }
  createGeneralForm() {

    this.generalForm = this.formBuilder.group({
      add_vehicle: [''],
      pro_pic: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      password: ['', [Validators.minLength(6), this.addMode ? Validators.required : Validators.nullValidator]],
      verify_password: ['', [Validators.minLength(6), this.addMode ? Validators.required : Validators.nullValidator]],
      city: ['', Validators.required],
      postal_code: ['', [Validators.required, Validators.minLength(4), Validators.pattern(/^[0-9]*$/)]],
      contact: ['', [Validators.required, Validators.minLength(10), mobileNumberValidator(10)]],
      email: ['', [Validators.required, Validators.email]],
      id_number: ['', [Validators.required, rsaIdValidator(), Validators.pattern(/^[0-9]*$/)]],
      street: ['', Validators.required],
      building: ['', Validators.required],
      suburb: ['', Validators.required],
      province: ['', Validators.required],
      work_street: ['', Validators.required],
      work_building: ['', Validators.required],
      work_suburb: ['', Validators.required],
      work_city: ['', Validators.required],
      work_province: ['', Validators.required],
      work_p_code: ['', [Validators.required, Validators.minLength(4), Validators.pattern(/^[0-9]*$/)]],
      kin: ['', Validators.required],
      kin_relation: ['', Validators.required],
      kin_contact: ['', [Validators.required, mobileNumberValidator(10)]],
      gross_income: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      net_income: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      designation: ['', Validators.required],
      device_type: ['', Validators.required],
      practice_no: [''],
      bank_name: [''],
      bank_acc_no: [''],
      bank_acc_type: [''],
      bank_acc_holder: [''],
      branch: [''],
      branch_code: [''],
      reg_fee: [0],
      accepted_terms: [''],
      signature: [''],
      register_date: new Date(),
      company: [''],
      company_reg: [''],
      company_tel: [''],
      dealer_id: [this.dealerId],
      device: [''],
      last_login: [''],
      reg_status: ['incomplete']
    });
    this.generalForm.get('verify_password').setValidators([Validators.minLength(6),
      this.addMode ? Validators.required : Validators.nullValidator,
      this.formService.customValidator('passwordsMustMatch', () => {
        return this.password !== this.verifyPassword;
      })
    ]);

    this.generalForm.valueChanges.subscribe(() => {
      this.formErrors = this.formService.validateForm(this.generalForm, this.formErrors);
    });
    if (!this.addMode) {
      this.doctorService.getById(this.customerId)
        .subscribe((res) => {
          const result = Object.assign({}, res);
          result.province = this.titleCase.transform(res.province);
          result.work_province = this.titleCase.transform(res.work_province);
          result.password = '';
          this.generalForm.patchValue(result);
        });
    }
  }

  updateForm(addVehicleFormFields) {
    if (addVehicleFormFields && (this.generalForm.get('make') === null || this.generalForm.get('make') === undefined)) {
      this.generalForm.addControl('make', new FormControl('', [Validators.required]));
      this.generalForm.addControl('year', new FormControl('', [Validators.required]));
      this.generalForm.addControl('model', new FormControl('', [Validators.required]));
      this.generalForm.addControl('kilos', new FormControl('', [Validators.required]));
      this.generalForm.addControl('vin', new FormControl('', [Validators.required]));
      this.generalForm.addControl('color', new FormControl('', [Validators.required]));
      this.generalForm.addControl('reg', new FormControl('', [Validators.required]));
      this.generalForm.addControl('engine_no', new FormControl('', [Validators.required]));
      this.generalForm.addControl('finance', new FormControl('', [Validators.required]));
      this.generalForm.addControl('finance_bank', new FormControl(''));
    } else if (!addVehicleFormFields && (this.generalForm.get('make') !== null && this.generalForm.get('make') !== undefined)) {
      this.generalForm.removeControl('make');
      this.generalForm.removeControl('year');
      this.generalForm.removeControl('model');
      this.generalForm.removeControl('kilos');
      this.generalForm.removeControl('vin');
      this.generalForm.removeControl('color');
      this.generalForm.removeControl('reg');
      this.generalForm.removeControl('engine_no');
      this.generalForm.removeControl('finance');
      this.generalForm.removeControl('finance_bank');
    }
  }
  createRestrictionForm() {
    this.restrictionForm = this.formBuilder.group({
      products: [''],
      category: [''],
      min: [''],
      max: ['']
    });
  }

  createUsageForm() {
    this.usageForm = this.formBuilder.group({
      limit: ['', Validators.required],
      customer: ['']
    });
  }

  getProvinces() {
    return [
      { id: '1', name: 'Gauteng' },
      { id: '2', name: 'North West' },
      { id: '3', name: 'KwaZulu Natal' },
      { id: '4', name: 'Limpopo' },
      { id: '5', name: 'Freestate' },
      { id: '6', name: 'Mpumalanga' },
      { id: '7', name: 'Northern Cape' },
      { id: '8', name: 'Western Cape' },
      { id: '9', name: 'Eastern Cape' },
    ];
  }

  getDesignation() {
    return [
      { id: 'Normal', name: 'Normal' },
      { id: 'Normal', name: 'CEO' },
    ];
  }

  getDevices() {
    return [
      { id: 'Apple IOS', name: 'IOS' },
      { id: 'Google Android', name: 'Android' },
    ];
  }
  ngOnInit() {
    // :toto make electronic dead


    // .getByDealerId(this.dealerId)
    // .subscribe((data) => {
    //   this.transactions = new LocalDataSource(data);
    // });
  }

  registerCustomer(){
    if (this.generalForm.valid) {
      const data = Object.assign({}, this.generalForm.value);
      if (data.verify_password) {
        delete data.verify_password;
        delete data.add_vehicle;
        if (!this.showVehicleDetails) {
          delete data.make;
          delete data.model;
          delete data.year;
          delete data.kilos;
          delete data.vin;
          delete data.color;
          delete data.reg;
          delete data.engine_no;
          delete data.finance;
        }
      }
      this.doctorService.create(data)
        .subscribe((result) => {
          if (this.showVehicleDetails) {
            const vehicleObj: Vehicle|any = {
              make: data.make,
              model: data.model,
              year: data.year,
              kilos: data.kilos,
              vin: data.vin,
              color: data.color,
              reg: data.reg,
              engine_no: data.engine_no,
              mm_code: '',
              finance: data.finance,
              finance_bank: this.hideFinanceRadioBtn ? 'N/A' : data.finance_bank,
              owner_id: result.id.toString(),
              dealer_id: Number(this.dealerId),
              signature: '',
              other_tracking: '',
              pay_date: '',
              tracking_subscription: '',
              tracking_package: '',
              tracking: '',
              next_service: ''
            };
            this.vehicleService.create(vehicleObj).subscribe(() => {
              this.dryService.presentToast('Record created successfully');
              this.generalForm.reset();
              window.scroll(0, 0);
            });
          } else {
            this.dryService.presentToast('Record created successfully');
            window.scroll(0, 0);
          }
        },
          () => {
            this.dryService.presentToast('Customer registration failed.');
          });
    } else {
      this.generalForm.markAsDirty();
      this.generalForm.markAllAsTouched();
      this.formErrors = this.formService.validateForm(this.generalForm, this.formErrors);
      this.dryService.presentToast('Some form fields are invalid.');
    }
  }

  updateCustomer(event) {
    event.preventDefault();
    // TODO: UPDATE COMPONENT - change update, check paramaters
    if (this.generalForm.valid) {
      // this.doctorService.update("id", this.generalForm.value)
      //   .subscribe(() => {
      //     //Todo: and logic after add
      //   })
    }
  }

  addVehicleOption($event: any) {
    const showDetails = $event === 'Yes';
    this.updateForm(showDetails);
    this.showVehicleDetails = showDetails;
  }

  updateVehicleModels(make: any) {
    if (make === 'VW') {
      this.vehicleModel = DropDownConstants.vehicleModels.VW;
    } else if (make === 'Audi') {
      this.vehicleModel = DropDownConstants.vehicleModels.audi;
    }
  }

  vehicleFinanceChange($event) {
    this.hideFinanceRadioBtn = $event.value !== 'Financed';
  }
}
