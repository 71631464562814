<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>New Cars Enquiries</h5>
            <small>More fuctionality to be added soon.</small>
        </div>
        <div class="card-body">
            <section>
                <form class="needs-validation">
                <div class="row">
                        <div class="col-md-2 form-group">
                            <label for="validationCustom0" class="mr-2">
                                Status</label>
                            <Select type="text" class="form-control">
                                <option value="" selected>All</option>
                                <option value="">All</option>
                            </Select>
                        </div>
                        <div class="col-md-2 form-group">
                            <label for="validationCustom0" class="mr-2">
                                Deal Status</label>
                            <Select type="text" class="form-control">
                                <option value="" selected>All</option>
                                <option value="">All</option>
                            </Select>
                        </div>
                        <div class="col-md-2 form-group">
                            <label for="validationCustom0" class="mr-2">
                                Filter By Date</label>
                            <Select type="text" class="form-control">
                                <option value="" selected>All</option>
                                <option value="">All</option>
                            </Select>
                        </div>
                        <div class="m-t-20 pull-right">
                            <button class="btn btn-primary m-b-10" type="button">Search</button>
                        </div>
                    </div>
                </form>
            </section>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->