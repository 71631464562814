export class transactionsDB {
    static data = [
        {
            order_id:"142",
            transaction_id: "#212145214510",
            date: "Sep 2,19",
            pay_method: "Paypal",
            delivery_status:"Pending",
            amount: "R175/-"
        },
        {
            order_id: "217",
            transaction_id: "#784561421721",
            date: "Dec 10,19",
            pay_method: "Stripe",
            delivery_status:"Process",
            amount: "R845/-"
        },
        {
            order_id: "546",
            transaction_id: "#476547821142",
            date: "Feb 15,19",
            pay_method: "Stripe",
            delivery_status:"Delivered",
            amount: "R314/-"
        },
        {
            order_id: "617",
            transaction_id: "#745384127541",
            date: "Mar 27,19",
            pay_method: "Paypal",
            delivery_status:"Pending",
            amount: "R217/-"
        },
        {
            order_id: "565",
            transaction_id: "#96725125102",
            date: "Sep 1,19",
            pay_method: "Stripe",
            delivery_status:"Process",
            amount: "R542/-"
        },
        {
            order_id: "754",
            transaction_id: "#547121023651",
            date: "May 18,19",
            pay_method: "Paypal",
            delivery_status:"Pending",
            amount: "R2141/-"
        },
        {
            order_id: "164",
            transaction_id: "#876412242215",
            date: "Jan 14,19",
            pay_method: "Stripe",
            delivery_status:"Delivered",
            amount: "R4872/-"
        },
        {
            order_id: "841",
            transaction_id: "#31534221621",
            date: "Apr 22,19",
            pay_method: "Paypal",
            delivery_status:"Process ",
            amount: "R7841/-"
        },
        {
            order_id: "354",
            transaction_id: "#78412457421",
            date: "Mar 26,19",
            pay_method: "Paypal",
            delivery_status:"Pending",
            amount: "R2784/-"
        },
        {
            order_id: "784",
            transaction_id: "#241524757448",
            date: "Feb 29,19",
            pay_method: "Stripe",
            delivery_status:"Delivered",
            amount: "R461/-"
        },
        {
            order_id: "142",
            transaction_id: "#212145214510",
            date: "Sep 2,19",
            pay_method: "Paypal",
            delivery_status:"Pending",
            amount: "R147/-"
        },
        {
            order_id: "217",
            transaction_id: "#784561421721",
            date: "Dec 10,19",
            pay_method: "Stripe",
            delivery_status:"Process ",
            amount: "R845/-"
        },
        {
            order_id: "547",
            transaction_id: "#476547821142",
            date: "Feb 15,19",
            pay_method: "Stripe",
            delivery_status:"Delivered ",
            amount: "R317/-"
        },
        {
            order_id: "671",
            transaction_id: "#745384127541",
            date: "Mar 27,19",
            pay_method: "Paypal",
            delivery_status:"Pending",
            amount: "R217/-"
        },
        {
            order_id: "565",
            transaction_id: "#96725125102",
            date: "Sep 1,19",
            pay_method: "Stripe",
            delivery_status:"Process ",
            amount: "R542/-"
        },
    ]
}