import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { StaffAccountsComponent } from './staff-accounts/staff-accounts.component';
import { CreateStaffAccountComponent } from './staff-accounts/create-staff-account/create-staff-account.component';
import { CreateDealerAccountComponent } from './dealer-accounts/create-dealer-account/create-dealer-account.component';
import { DealeraccountsComponent } from './dealer-accounts/dealeraccounts/dealeraccounts.component';


const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: "Profile",
      breadcrumb: "Profile"
    }
  },
  {
    path: 'staffaccounts',
    component: StaffAccountsComponent,
    data: {
      title: "Staff Accounts",
      breadcrumb: "Staff Accounts"
    }
  },
  {
    path: 'staffaccount/create',
    component: CreateStaffAccountComponent,
    data: {
      title: "Create Staff Accounts",
      breadcrumb: "Create Staff Accounts"
    }
  },
  {
    path: 'dealeraccounts',
    component: DealeraccountsComponent,
    data: {
      title: "Dealer Accounts",
      breadcrumb: "Dealer Accounts"
    }
  },
  {
    path: 'dealeraccount/create',
    component: CreateDealerAccountComponent,
    data: {
      title: "Create Dealer Accounts",
      breadcrumb: "Create Dealer Accounts"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
