import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Aircraft } from '../interfaces/Aircraft';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {

  private apiServer = environment.appEngineURL;
  public token = JSON.parse(localStorage.getItem('currentUser')).token;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  }
  constructor(private httpClient: HttpClient, private router: Router) { }

  create(aircraft: Aircraft): Promise<any> {
    const dealer_id = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    aircraft.dealer_id = dealer_id;
    return new Promise((resolve, reject) => {
      this.httpClient.post<Aircraft>(this.apiServer + '/aircrafts/', JSON.stringify(aircraft), this.httpOptions)
        .subscribe(response => {
          resolve(response);
        },
          error => reject(error));
    });
  }
  // create(aircraft: Aircraft): Observable<any> {
  //   const dealer_id = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
  //   aircraft.dealer_id = dealer_id;
  //   return this.httpClient.post<Aircraft>(this.apiServer + '/aircrafts/', JSON.stringify(aircraft), this.httpOptions)
  //     .pipe(
  //       catchError(this.errorHandler)
  //     )
  // }

  getById(id): Observable<any> {
    return this.httpClient.get<Aircraft>(this.apiServer + '/aircrafts/' + id)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getByDealerId(id): Observable<Aircraft> {
    return this.httpClient.get<Aircraft>(this.apiServer + '/aircrafts/' + id, this.httpOptions)
      .pipe(map((response: any) => response.json()));
  }

  getAll(): Observable<Aircraft[]> {
    return this.httpClient.get<Aircraft[]>(this.apiServer + '/aircrafts/', this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  update(id, product): Observable<Aircraft> {
    return this.httpClient.put<Aircraft>(this.apiServer + '/aircrafts/' + id, JSON.stringify(product), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  delete(id): Observable<any> {
    return this.httpClient.delete<any>(this.apiServer + '/aircrafts/' + id, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  markAsSold(id): Observable<any> {
    const soldObj = {
      "status": "Sold"
    };
    return this.httpClient.put<any>(this.apiServer + '/aircrafts/' + id, JSON.stringify(soldObj), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}