import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private apiServer = environment.appEngineURL;
  public token = JSON.parse(localStorage.getItem('currentUser')).token;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`
    })
  };


  constructor(private httpClient: HttpClient) {}


  // public upldmdea(image: File, category, dealerid?): Observable<any> {
  //   const formData = new FormData();
  //   let _dealerId;
  //   if (dealerid){
  //     _dealerId = dealerid;
  //   } else {
  //     _dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
  //   }
  //   formData.append('file', image);
  //   return this.httpClient.post(this.apiServer + '/dealer/file/' + _dealerId + '/' + category, formData)
  //   .pipe(map(results => results));
  // }

  // TODO: TUMI - Done
  public uploadMediaPromise(image: File, category, dealerid?): Promise<any> {
    const formData = new FormData();
    let _dealerId;
    if (dealerid){
      _dealerId = dealerid;
    } else {
      _dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    }
    formData.append('file', image);
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiServer + '/dealer/file/' + _dealerId + '/' + category, formData)
        .subscribe(response => {
            resolve(response);
          },
          error => {
            reject(error);
          });
    });
  }

  mediaEntityLink(entityId, imageLink, entityCategory): Promise<any>{
    let entityUrl = '';
    if (!entityCategory) {
      return;
    }
    // TODO: FINISH THE SWITCH STATEMENTS - Done
    switch (entityCategory) {
      case 'usedcar':
        entityUrl = 'usedCarMedia';
        break;
      case 'newcar':
        entityUrl = 'newCarMedia';
        break;
      case 'aircraft':
        entityUrl = 'aircraftMedia';
        break;
      case 'houses':
        entityUrl = 'houseMedia';
        break;
      case 'jewellery':
        entityUrl = 'jewelleryMedia';
        break;
      case 'yacht':
        entityUrl = 'yachtMedia';
        break;
      case 'dealerAccount':
        entityUrl = 'dealerAccountMedia';
        break;
      case 'dealerStaffAccount':
        entityUrl = 'dealerStaffAccountMedia';
        break;
      // case "auction":
      //   entityUrl = "Auction";
      //   break;
      // case "newstock":
      //   entityUrl = "Auction";
      //   break;
      default:
        break;
    }
    debugger;
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiServer + '/' + entityUrl + '/' + entityId, imageLink, this.httpOptions)
        .subscribe(response => {
          resolve(response);
        },
          error => reject(error));
    });
  }
}
