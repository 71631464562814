export class reportDB {
    static report = [
        {
            name: "Gray Brody",
            id: "14783112",
            date: "Nov 20, 2018",
            total:"R745",
        },
        {
            name: "Perez Alonzo",
            id: "87541221",
            date: "Dec 25, 2018",
            total:"R8136",
        },
        {
            name: "woters maxine",
            id: "213514462",
            date: "Feb 04, 2019",
            total:"R564",
        },
        {
            name: "Rowan torres",
            id: "7512785568",
            date: "Jan 07, 2019",
            total:"R2364",
        },
        {
            name: "Lane Skylar",
            id: "7614585124",
            date: "Apr 15, 2019",
            total:"R671",
        },
        {
            name: "alexander",
            id: "574225447",
            date: "Mar 21, 2019",
            total:"R8914",
        },
        {
            name: "christian",
            id: "235896144",
            date: "Mar 28, 2019",
            total:"R7952",
        },
        {
            name: "stephanie",
            id: "5781425474",
            date: "Feb 30, 2019",
            total:"R1236",
        },
        {
            name: "victoria",
            id: "636512214",
            date: "Mar 12, 2019",
            total:"R8914",
        },
        {
            name: "campbell",
            id: "461178242",
            date: "Apr 19, 2019",
            total:"R94174",
        },
        {
            name: "austin",
            id: "548212314",
            date: "Jan 26, 2019",
            total:"R8914",
        },
    ]
}