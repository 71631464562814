import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { DealerStaffAccountsService } from 'src/app/shared/service/dealer-staff-accounts.service';
import { DealerService } from 'src/app/shared/service/dealer.service';
import { DryService } from 'src/app/shared/service/dry.service';
import { ImageService } from 'src/app/shared/service/image.service';
import { AuthenticationService } from '../../../../_services/authentication.service';


@Component({
  selector: 'app-create-staff-account',
  templateUrl: './create-staff-account.component.html',
  styleUrls: ['./create-staff-account.component.scss']
})
export class CreateStaffAccountComponent implements OnInit {

  public signupForm: FormGroup;

  public firstName;
  public occupation;
  public department;
  // public designation;
  public userName;
  public dealer_id;
  public dealer_name;
  public email;
  public profilePicture;
  public staffId;

  public view!: boolean;
  public addMode!: boolean;


  public admin!:boolean;
  public manager!:boolean;
  public selected!:boolean;

  designations;
  provinces;


  public url = [
    {
      img: "assets/images/dashboard/man.png",
    }
  ];

  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    public router: Router,
    private imageService: ImageService,
    private matDialog: MatDialog,
    private dryService: DryService,
    private route: ActivatedRoute,
    private dealerStaffAccount : DealerStaffAccountsService,
    private dealerAccount : DealerService,
    private titleCase: TitleCasePipe,

  ) {
    const viewParam = this.route.snapshot.queryParams.view;
    this.staffId = this.route.snapshot.queryParams.staffId;
    this.view = viewParam;
    this.addMode = !this.staffId;


    this.dealer_id = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    this.admin = JSON.parse(localStorage.getItem('currentUser')).designation == 'Admin'
    this.manager = JSON.parse(localStorage.getItem('currentUser')).designation == 'Manager'
    this.dealer_name = JSON.parse(localStorage.getItem('dealerAccount')).dealer_name;
    this.createSignupForm();
    this.provinces = this.getProvinces()
  }

  onChange($event){
    if ($event) {
      this.dealer_name = $event.value.name;
      this.dealer_id = $event.value.id;
      this.signupForm.get('dealer_id').setValue(this.dealer_id);
      this.selected = true;
    }
  }

  createSignupForm() {
    this.signupForm = this.formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      username: ['', Validators.required],
      title: ['', Validators.required],
      img: ['assets/images/user.png'],
      contact: ['', Validators.required],
      department: ['', Validators.required],
      dealer_id: [this.dealer_id],
      designation: ['', Validators.required],
      occupation: ['', Validators.required],
      password: ['', Validators.minLength(6)],
      // password: ['', Validators.minLength(6), this.addMode ? Validators.required : Validators.nullValidator],
    });
    if (!this.addMode) {
      this.dealerStaffAccount.getById(this.staffId)
        .subscribe((res) => {
          const result = Object.assign({}, res);
          result.designation = this.titleCase.transform(res.designation);
          result.password = '';
          this.url[0].img = res.media[0] ? res.media[0]?.medium?.url : 'assets/images/dashboard/man.png';
          this.signupForm.patchValue(result);
        });
    }
  }
  designationList(designationValue) {
    this.signupForm.get('designation').setValue(designationValue);
  }

  getProvinces() {
    let results;
    this.dealerAccount.getAll().then(res => {
      results = res.map((obj) => {
        return {
          'id':obj.id,
          'dealer_name':obj.dealer_name
        }
      });
      this.provinces = results;
      // results = Object.assign({}, res);
      // console.log(results);
    });
    return results;
    return [
      { id: '1', name: 'Gauteng' },
      { id: '2', name: 'North West' },
      { id: '3', name: 'KwaZulu Natal' },
      { id: '4', name: 'Limpopo' },
      { id: '5', name: 'Freestate' },
      { id: '6', name: 'Mpumalanga' },
      { id: '7', name: 'Northern Cape' },
      { id: '8', name: 'Western Cape' },
      { id: '9', name: 'Eastern Cape' },
    ];
  }

  // FileUpload
  readUrl(event: any, i) {

    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    let mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.selectedFiles.push(event.target.files);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    };
  }
  selectedFiles = [];

  async imageUpload(formId) {
    const linkArray = [];
    const productType = 'dealerStaffAccount';
    // TODO: TUMI
    for (const x of this.selectedFiles) {
      const i = this.selectedFiles.indexOf(x);
      await this.imageService.uploadMediaPromise(x[0], productType)
        .then(res => {
          res.sequence = i;
          linkArray.push(res);
          this.imageService.mediaEntityLink(formId, linkArray[i], productType)
            .then(mediaResponse => {
            }, err => { alert(err.error); },
            );
        }, err => { console.log(err); }
        );
    }
    this.confirmationRouter('created');
  }
  confirmationRouter(mode) {
    this.dryService.presentToast(`Record ${mode} successfully`);
    // this.signupForm.reset();
    this.router.navigate(['settings/staffaccounts']);
  }
  signUpAccount() {
    // this.signupForm.get('dealer_id').setValue(this.provinces.id);
    if (this.signupForm.invalid) {
      this.dryService.presentToast(`Please make sure all fields are entered`);
      return;
    };

    this.authService.create(this.signupForm.value)
      .subscribe(formResult => {
        this.imageUpload(formResult.id);
      })
  }
  updateAccount() {
    if (this.signupForm.invalid) {
      this.dryService.presentToast(`Please make sure all fields are entered`);
      return;
    };

    this.dealerStaffAccount. update(this.staffId, this.signupForm.value)
      .subscribe(() => {
        this.confirmationRouter('updated');
      });
  }
  cancel(){
    this.router.navigate(['settings/staffaccounts']);
  }

  ngOnInit() { }

}
