import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerAccountsComponent } from './customer-accounts/customer-accounts.component';
import { CustomerRegisterComponent } from './customer-register/customer-register.component';
import { CustomerVehiclesComponent } from './customer-vehicles/customer-vehicles.component';
import { NeedsAnalysisComponent } from './needs-analysis/needs-analysis.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'customer-register',
        component: CustomerRegisterComponent,
        data: {
          title: "Register Customer",
          breadcrumb: "Register Customer"
        }
      },
      {
        path: 'customer-accounts',
        component: CustomerAccountsComponent,
        data: {
          title: "Customer Accounts",
          breadcrumb: "Customer Accounts"
        }
      },
      {
        path: 'customer-vehicles',
        component: CustomerVehiclesComponent,
        data: {
          title: "Customer Vehicles",
          breadcrumb: "Customer Vehicles"
        }
      },
      {
        path: 'needs-analysis',
        component: NeedsAnalysisComponent,
        data: {
          title: "Needs Analysis",
          breadcrumb: "Needs Analysis"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
