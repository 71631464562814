<!-- Container-fluid starts-->
<div class="container-fluid">
    <section>
        <div class="card">
            <!-- <div class="card-header"> -->
            <!-- MOVE COMPONENT TODO: NEEDS ANALYSIS -->
            <!-- <h5>My Clients</h5> -->
            <!-- </div> -->
            <div class="card-body">
                <form class="needs-validation">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-4 form-group">
                                <label for="validationCustom0"  >
                                    Customer ID Number</label>
                                <input type="text" class="form-control" >
                            </div>
                            <div class="col-sm-3 form-group">
                                <label for="validationCustom0"  >
                                    Deal Status</label>
                                <input type="text" class="form-control" >
                            </div>
                            <div class="col-sm-2 form-group">
                                <label for="validationCustom0"  >
                                    Year</label>
                                <input type="text" class="form-control" >
                            </div>
                            <div class="col-sm-2 form-group">
                                <label for="validationCustom0"  >
                                    Month</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 form-group">
                                <label for="validationCustom0" class="mr-2">
                                    Filter By Sales Executive</label>
                                <input type="text" class="form-control" >
                            </div>
                            <div class="m-t-20">
                                <button class="btn btn-primary" type="button">Search</button>
                                <button class="btn btn-primary m-l-10" type="button">Clear</button>
                                <button class="btn btn-secondary m-l-50" type="button">Targets</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->