<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 *ngIf="addMode">Add {{header}}</h5>
                    <h5 *ngIf="!addMode">Edit {{header}}</h5>
                    <!-- <h5>Add Product</h5> -->
                </div>
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <img [src]="imageSrc? imageSrc : 'assets/images/pro3/1.jpg'" alt="" class="img-fluid image_zoom_1">
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file">
                                                    <input class="upload" type="file" multiple (change)="readUrl($event,i)">
                                                    <img class="imgUpload" alt="" [src]="item.img">
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <div class="add-product-form">
                                <!-- USED CARS FORM GROUP -->
                                <form class="needs-validation" [formGroup]="usedCarForm"
                                    *ngIf="productType == 'usedcar'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Vehicle Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Make</label>
                                                <input type="text" formControlName="brand" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Model</label>
                                                <input type="text" formControlName="model" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Year</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="year" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Engine Size</label>
                                                <input type="text" formControlName="engine_size" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Mileage</label>
                                                <input type="text" formControlName="kilos" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Vin</label>
                                                <input type="text" formControlName="vin" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Price</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="price" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Color</label>
                                                <input type="text" formControlName="color" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Transmission</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="transmission" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Fuel Type</label>
                                                <input type="text" formControlName="fuel" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Doors</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="doors" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Body Type</label>
                                                <input type="text" formControlName="type" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Kilowatts</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="kilos" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Service History</label>
                                                <input type="text" formControlName="service_history" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Installment</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="installment" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Term (Months)</label>
                                                <input type="text" formControlName="term" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Add Description</label>
                                                <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- NEW CARS FORM GROUP  -->
                                <form class="needs-validation" [formGroup]="newCarForm"
                                    *ngIf="productType == 'newcar'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Vehicle Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Make</label>
                                                <input type="text" formControlName="brand" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Model</label>
                                                <input type="text" formControlName="model" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Year</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="year" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Engine Size</label>
                                                <input type="text" formControlName="engine_size" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Mileage</label>
                                                <input type="text" formControlName="kilos" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Vin</label>
                                                <input type="text" formControlName="vin" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Price</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="price" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Color</label>
                                                <input type="text" formControlName="color" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Transmission</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="transmission" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Fuel Type</label>
                                                <input type="text" formControlName="fuel" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Doors</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="doors" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Body Type</label>
                                                <input type="text" formControlName="type" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Kilowatts</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="kilos" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Service History</label>
                                                <input type="text" formControlName="service_history" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Installment</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="installment" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Term (Months)</label>
                                                <input type="text" formControlName="term" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Add Description</label>
                                                <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- NEW AIRCRAFT FORM GROUP  -->
                                <form class="needs-validation" [formGroup]="newAircraftForm"
                                    *ngIf="productType == 'aircraft'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Aircraft Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Category</label>
                                                <input type="text" formControlName="category" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Model</label>
                                                <input type="text" formControlName="model" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Passengers</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="number" formControlName="pax" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Engine Size</label>
                                                <input type="text" formControlName="engine_size" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    YOM</label>
                                                <input type="text" formControlName="yom" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    True Airspeed</label>
                                                <input type="text" formControlName="true_airspeed" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Price</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="price" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Cabin Features</label>
                                                <input type="text" formControlName="cabin_features" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Restroom</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="restroom" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    IFR Range</label>
                                                <input type="text" formControlName="ifr_range" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Endurance</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="endurance" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Crew</label>
                                                <input type="text" formControlName="crew" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Communications</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="communications" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Registration</label>
                                                <input type="text" formControlName="registration" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Insurance Cover</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="insurance_cover" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Add Description</label>
                                                <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- NEW HOUSE FORM GROUP  -->
                                <form class="needs-validation" [formGroup]="houseForm"
                                    *ngIf="productType == 'houses'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>House Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Title</label>
                                                <input type="text" formControlName="title" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Area</label>
                                                    <!-- TODO: MAKE AREA A DROP DOWN -->
                                                <input type="text" formControlName="area" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    City</label>
                                                    <!-- TODO: MAKE CITY A DROP DOWN -->
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="city" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    People</label>
                                                <input type="text" formControlName="people" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bedrooms</label>
                                                <input type="text" formControlName="bedrooms" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bathrooms</label>
                                                <input type="text" formControlName="bathrooms" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Price</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="price" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Category</label>
                                                <input type="text" formControlName="category" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Add Description</label>
                                                <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- NEW JEWELLERY FORM GROUP  -->
                                <form class="needs-validation" [formGroup]="jewelleryForm"
                                    *ngIf="productType == 'jewellery'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Jewellery Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Title</label>
                                                <input type="text" formControlName="title" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Category</label>
                                                    <!-- TODO: MAKE AREA A DROP DOWN -->
                                                <input type="text" formControlName="category" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Price</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="price" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Code</label>
                                                <input type="text" formControlName="code" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Add Description</label>
                                                <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- NEW YACHT FORM GROUP  -->
                                <form class="needs-validation" [formGroup]="yachtForm"
                                    *ngIf="productType == 'yacht'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Yacht Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Model</label>
                                                <input type="text" formControlName="model" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Engine</label>
                                                    <!-- TODO: MAKE AREA A DROP DOWN -->
                                                <input type="text" formControlName="engines" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Beam</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="beam" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Draft</label>
                                                <input type="text" formControlName="draft" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Water Capacity</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="water_capacity" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Black Water Tank</label>
                                                <input type="text" formControlName="black_water_tank" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Hot Water Calorifier</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="hot_water_calorifier" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Length Overall</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="length_overall" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Fuel Capacity</label>
                                                <input type="text" formControlName="fuel_capacity" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Displacement</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" formControlName="displacement" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Add Description</label>
                                                <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- NEW STOCK FORM GROUP -->
                                <form [formGroup]="newStockForm" *ngIf="productType == 'newstock'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Item Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Make</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Model</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Vin</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Color</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Code</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Physical</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Customer</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Vehicle Grade</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Stock Number</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Days</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Status</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Unit Status</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Engine Size</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Boat Name</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Type</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Has Tracker</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Notes:</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">Extras:</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Select Dealers to share with</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <!-- AUCTION FORM GROUP -->
                                <form [formGroup]="auctionForm" *ngIf="productType == 'auction'">
                                    <section>
                                        <!-- <div class="form-group row"> -->
                                        <h4>Vehicle Details</h4>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Make</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Model</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Year</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Engine Size</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Mileage</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Vin</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Color</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Transmission</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Fuel Type</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Doors</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Body Type</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Kilowatts</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Service History</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bid Starting Price</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bid Start Date</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bid Start Time</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bid End Date</label>
                                                <!-- <input formControlName="suburb" type="text" class="form-control"   > -->
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="col form-group">
                                                <label for="validationCustom0" class="mr-2">
                                                    Bid End Time</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                    </section>
                                </form>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                                        <button type="button" class="btn btn-primary mr-1" *ngIf="addMode" (click)="addForm()">Add</button>
                                        <button type="button" class="btn btn-primary mr-1" *ngIf=!addMode (click)="updateForm()">Save</button>
                                        <button type="button" class="btn btn-light" (click)="discard()">Discard</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->