import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DealerAccount } from '../interfaces/DealerAccount';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  private apiServer = environment.appEngineURL;
  public token = JSON.parse(localStorage.getItem('currentUser')).token;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  }
  constructor(private httpClient: HttpClient, private router: Router) { }

  create(dealerAccount: DealerAccount): Observable<DealerAccount> {
    return this.httpClient.post<DealerAccount>(this.apiServer + '/dealerAccounts/', JSON.stringify(dealerAccount), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getById(id): Observable<DealerAccount> {
    return this.httpClient.get<DealerAccount>(this.apiServer + '/dealerAccounts/' + id, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getByDealerId(): Observable<any> {
    const dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    console.log(dealerId);
    return this.httpClient.get(this.apiServer + '/dealerAccounts/dealer/' + dealerId, this.httpOptions)
      .pipe();
  }

  getAll():Promise<any> {
    return  new Promise((resolve, reject) => {
      this.httpClient.get<DealerAccount[]>(this.apiServer + '/dealerAccounts/', this.httpOptions)
      .subscribe(response => {
        resolve(response);
      },
      error => {
        reject(error);
      });
    })
  }

  update(id, product): Observable<DealerAccount> {
    return this.httpClient.put<DealerAccount>(this.apiServer + '/dealerAccounts/' + id, JSON.stringify(product), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  delete(id) {
    return this.httpClient.delete<DealerAccount>(this.apiServer + '/dealerAccounts/' + id, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}