import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DryService {

  constructor(private toast: MatSnackBar) { }

  presentToast(message: string, action = 'Ok', duration = 10000) {
    this.toast.open(message, action, {duration});
  }
}
