import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { UsedCarsEnquiriesComponent } from './new-cars-enquiries/used-cars-enquiries.component';
import { JewelleryComponent } from './jewellery/jewellery.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'jewellery',
        component: JewelleryComponent,
        data: {
          title: "Jewellery",
          breadcrumb: "Jewellery"
        }
      },
      // {
      //   path: 'used-cars-enquiries',
      //   component: UsedCarsEnquiriesComponent,
      //   data: {
      //     title: "Used Cars Enquiries",
      //     breadcrumb: "Used Cars Enquiries"
      //   }
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class JewelleryRoutingModule { }
