import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { RepairBookingService } from 'src/app/shared/service/repairBookings.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'app-book-fault',
  templateUrl: './book-fault.component.html',
  styleUrls: ['./book-fault.component.scss']
})
export class BookFaultComponent implements OnInit {

  public transactions: any


  constructor(private faultService: RepairBookingService) {
    // TODO: Change TransactionDB to My ClIENTS DB
    faultService.getAll()
    .subscribe((data) => {
      this.transactions = new LocalDataSource(data);
    })
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      date: {
        name: 'amountaaaaa',
        title: '<i class="ion-document" title="YourAction"></i>'
      },
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      problem: {
        title: 'Problem', filter: false
      },
      mobile: {
        title: 'Contact', filter: false
      },
      vin: {
        title: 'Vin', filter: false
      },
      designation: {
        title: 'Designation', filter: false,
        type: 'html',
      },
      // TODO: CHANGE COLUMNS TO ACTION BUTTONS
      delivery_status: {
        title: 'Action', filter: false
      },
      amount: {
        title: 'Action', filter: false
      }
    },
  };

  ngOnInit(): void {
  }

}
