import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDoctorAccount } from '../interfaces/DoctorsAccount';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DoctorAccountService {

  private apiServer = environment.appEngineURL;
  public token = JSON.parse(localStorage.getItem('currentUser')).token;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`
    })
  };
  constructor(private httpClient: HttpClient) { }

  create(doctorsAccount: IDoctorAccount): Observable<IDoctorAccount> {
    return this.httpClient.post<IDoctorAccount>(this.apiServer + '/doctorAccounts/', JSON.stringify(doctorsAccount), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<IDoctorAccount> {
    return this.httpClient.get<IDoctorAccount>(this.apiServer + '/doctorAccounts/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  searchByIdNumber(id): Observable<IDoctorAccount> {
    return this.httpClient.get<IDoctorAccount>(this.apiServer + '/doctorAccounts/idLookUp/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getByDealerId(id): Observable<IDoctorAccount[]> {
    return this.httpClient.get<IDoctorAccount[]>(this.apiServer + '/doctorAccounts/dealer/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAll(): Observable<IDoctorAccount[]> {
    return this.httpClient.get<IDoctorAccount[]>(this.apiServer + '/doctorAccounts/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, product): Observable<IDoctorAccount> {
    return this.httpClient.put<IDoctorAccount>(this.apiServer + '/doctorAccounts/' + id, JSON.stringify(product), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id){
    return this.httpClient.delete<IDoctorAccount>(this.apiServer + '/doctorAccounts/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     alert('Something went wrong');
     return throwError(errorMessage);
  }
}
