import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transact',
  templateUrl: './transact.component.html',
  styleUrls: ['./transact.component.scss']
})
export class TransactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
