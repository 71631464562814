import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { DealerService } from 'src/app/shared/service/dealer.service';


@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  constructor(private router: Router) { }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    this.router.navigate(['settings/dealeraccount/create'], { queryParams: { dealerId: this.rowData.id } });

  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Delete</button>
  `,
})
export class ButtonTwoViewComponent implements ViewCell, OnInit {
  constructor(private dealerAccountService: DealerService,) { }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.dealerAccountService.delete(this.rowData.id).subscribe(res => {
      this.dealerAccountService.reloadCurrentRoute();
    });
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'app-dealeraccounts',
  templateUrl: './dealeraccounts.component.html',
  styleUrls: ['./dealeraccounts.component.scss']
})
export class DealeraccountsComponent implements OnInit {
  public transactions: any;
  error: any;
  loading: boolean;

  constructor(
     dealerStaffAccount: DealerService,
  ) {
    dealerStaffAccount.getAll()
      .then((data) => {
        this.transactions = new LocalDataSource(data)
      })
  }

  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      media: {
        title: 'Image',
        type: 'html',
        valuePrepareFunction: (media) => {
          const picture = media[0] ? media[0]?.medium?.url : 'assets/images/dashboard/man.png';
          return `<img width="50px" src="${picture}" />`;
        },
      },
      dealer_name: {
        title: 'Dealer Name', filter: false
      },
      brand: {
        title: 'Brand', filter: false
      },
      email: {
        title: 'Email', filter: false
      },
      contact: {
        title: 'Contact', filter: false
      },
      city: {
        title: 'City', filter: false,
      },
      viewButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
        },
      },
      editButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonTwoViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
        },
      },
    }
  };

  ngOnInit(): void {
  }

  onSearchStatus(query: string = '') {
    if (query === '') {

      this.transactions.setFilter([]);
      return;

    }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}