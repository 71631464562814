<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Customer Accounts</h5>
        </div>
        <div class="card-body">
            <form class="needs-validation">
                    <div class="col-8">
                    <section>
                        <div class="row">
                            <div class="col form-group">
                                <label for="cellNo" class="mr-2">
                                    Cell</label>
                                <input #search type="text" class="form-control" id="cellNo" placeholder="Search Cell" (keyup)="onSearchCell(search.value)">
                            </div>
                            <div class="col form-group">
                                <label for="IDno" class="mr-2">
                                    ID Number</label>
                                <input #search2 type="text" class="form-control" id="IDno" placeholder="Search ID" (keyup)="onSearchID(search2.value)">
                            </div>
                        </div>
                    </section>
                </div>
                    <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                        </div>
                    </div>
                </form>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
