import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DealerStaffAccountsService } from '../../service/dealer-staff-accounts.service';
import { NavService, Menu } from '../../service/nav.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  public fullName: string;
  public designation: string;
  public dealer_name: string;
  public profilePicture: string;

  constructor(
    private router: Router,
    public navServices: NavService,
    private dealerStaffAccountsService: DealerStaffAccountsService,
    private authService: AuthenticationService
  ) {

    let _userId: any = JSON.parse(localStorage.getItem('currentUser')).id;

    this.authService.getDealerAccount().then(res => {
      if (res?.dealer_name) {
        this.dealer_name = res ? res?.dealer_name : localStorage.getItem('dealerAccount') ? JSON.parse(localStorage.getItem('dealerAccount'))?.dealer_name : '';
      } else {
        this.dealer_name = localStorage.getItem('dealerAccount') ? JSON.parse(localStorage.getItem('dealerAccount'))?.dealer_name : '';
      }
    });
    if (this.profilePicture) {
      this.profilePicture = localStorage.getItem('profilePicture');
    } else {
      this.dealerStaffAccountsService.getById(_userId)
        .subscribe(formResult => {
          this.profilePicture = formResult?.media[0] ? formResult?.media[0].medium?.url : 'assets/images/dashboard/man.png';
          localStorage.setItem('profilePicture', this.profilePicture);
        });
    }

    this.fullName = JSON.parse(localStorage.getItem('currentUser')).firstName;
    this.designation = JSON.parse(localStorage.getItem('currentUser')).designation;
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    });

    this.dealer_name = localStorage.getItem('dealerAccount') ? JSON.parse(localStorage.getItem('dealerAccount'))?.dealer_name : '';
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
