import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Vehicle } from '../interfaces/Vehicle';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  private apiServer = environment.appEngineURL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  create(vehicle: Vehicle): Observable<Vehicle> {
    return this.httpClient.post<Vehicle>(this.apiServer + '/vehicles/', JSON.stringify(vehicle), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<Vehicle> {
    return this.httpClient.get<Vehicle>(this.apiServer + '/vehicles/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getByDealerId(id): Observable<Vehicle[]> {
    return this.httpClient.get<Vehicle[]>(this.apiServer + '/vehicles/dealer/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAll(): Observable<Vehicle[]> {
    return this.httpClient.get<Vehicle[]>(this.apiServer + '/vehicles/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, product): Observable<Vehicle> {
    return this.httpClient.put<Vehicle>(this.apiServer + '/vehicles/' + id, JSON.stringify(product), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id){
    return this.httpClient.delete<Vehicle>(this.apiServer + '/vehicles/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage;
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}
