<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Perform User Account Check</h5>
            <small>This section allows you to check if a customer is already registered.</small>
        </div>
        <div class="card-body">
            <section>
                <form class="needs-validation">
                    <div class="col-8">
                        <div class="row">
                            <div class="col form-group">
                                <label for="idNumber" class="mr-2">
                                    ID Nwumber:</label>
                                <input type="text" name="searchId" [(ngModel)]="searchParam" [ngModelOptions]="{standalone: true}" id="idNumber" class="form-control">
                            </div>
                            <div class="m-t-20">
                                <button class="btn btn-primary m-r-10" type="button">Search</button>
                                <!-- <button class="btn btn-secondary" type="button">Buy Now</button> -->
                            </div>
                        </div>
                        <!-- <div class="pull-right"> -->
                        <!-- </div> -->
                    </div>
                </form>
            </section>
          <section>
            <h3>User acccount found</h3>
            <p><strong>Name: </strong>{{userDetails.name | titlecase}} {{userDetails.surname | titlecase}}</p>
            <p><strong>ID Number: </strong>{{userDetails.id_number}}</p>
          </section>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h5>Needs Analysis Customer Registration</h5>
        </div>
        <div class="card-body">
            <div class="col-8">
                <form class="needs-validation">
                    <section>
                        <h4>Personal Details</h4>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Name</label>
                                <input type="text" [(ngModel)]="userDetails.name" [ngModelOptions]="{standalone: true}" readonly id="name" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Surname</label>
                                <input type="text" [(ngModel)]="userDetails.surname" [ngModelOptions]="{standalone: true}" readonly id="Surname" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Contact</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Email</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    ID Number</label>
                                <input type="text" class="form-control"   >
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Residential Address</h4>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Street</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Building</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Suburb</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    City</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Province</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Postal Code</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Work Address</h4>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Street</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Building</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Suburb</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    City</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Province</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Postal Code</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Income</h4>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Before Deductions</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    After Deductions</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Designation</h4>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Type</label>
                                <!-- TODO: Make Select, Appear Drop Down Dynamically -->
                                <select class="form-control">
                                    <option value="1" selected>Normal</option>
                                    <option value="2">Doctor</option>
                                    <option value="3">Business Owner</option>
                                    <option value="4"> Ceo</option>
                                    <option value="5">Manager</option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>User Device</h4>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Select Device Type</label>
                                <!-- TODO: Make Select-->
                                <select class="form-control">
                                    <option value="1" selected>Google Android</option>
                                    <option value="2">Apple IOS</option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Create Login Password</h4>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Password</label>
                                <!-- TODO: Make Select-->
                                <input type="password" class="form-control"   >
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Confirm Password</label>
                                <!-- TODO: Make Select-->
                                <input type="password" class="form-control"   >
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Vehicle Of Interest</h4>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Brand/Make</label>
                                <select class="form-control">
                                    <option value="" selected>Brand/Make</option>
                                    <option value="">Volkswagen</option>
                                </select>
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Model</label>
                                <select class="form-control">
                                    <option value="" selected>Model</option>
                                    <option value="">Amarok</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Color</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Color Option 2</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Color Option 3</label>
                                <input type="text" class="form-control"   >
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Type</label>
                                <select class="form-control">
                                    <option value="" selected>Passenger</option>
                                    <option value="">Commercial</option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Key Features</h4>
                        <div class="row">
                            <div class="col-md">
                                <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                    <label class="d-block" for="edo-ani">
                                        <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani">
                                        Park Distance Control
                                    </label>
                                    <label class="d-block" for="edo-ani1">
                                        <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                        Sports Package
                                    </label>
                                    <label class="d-block" for="edo-ani2">
                                        <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                        Sunroof
                                    </label>
                                    <label class="d-block" for="edo-ani3">
                                        <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                        Smash & Grab
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Extra Features<small> (Please enter a new feature on a new line.)</small></h4>
                        <div class="row">
                            <div class="form-group">
                                <div class="col-md">
                                    <textarea type="text" id="ExtraFeatures" rows="5" cols="60"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Engine Size</label>
                                <select class="form-control">
                                    <option value="" selected>1.0</option>
                                </select>
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Transmission</label>
                                <select class="form-control">
                                    <option value="" selected>Manual</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Fuel Type </label>
                                <select class="form-control">
                                    <option value="" selected>Petrol</option>
                                </select>
                            </div>
                            <div class="col form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Do you have a deposit?</label>
                                <select class="form-control">
                                    <option value="" selected>Manual</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Desired Installment Amount</label>
                                <input type="text" class="form-control"   >
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Trade In</h4>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Trade in current vehicle?</label>
                                <!-- TODO: Make Select, Appear Drop Down Dynamically -->
                                <select class="form-control">
                                    <option value="1" selected>No</option>
                                    <option value="2">Yes</option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Notes <small>(2nd Vehcile Optiop)</small></h4>
                        <div class="row">
                            <div class="form-group">
                                <div class="col-md">
                                    <textarea type="text" id="ExtraFeatures" rows="5" cols="60"></textarea>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>Set Appointment</h4>
                        <div class="row">
                            <div class="col-md form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Next Appointment Date:</label>
                                <div class="input-group col-md-7 input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                     #c="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="c.toggle()" type="button"><i
                                                class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Next Appointment Time:</label>
                                <!-- TODO: CHANGE TIME PICKER -->
                                <select class="form-control">
                                    <option value="1" selected>15h00</option>
                                    <option value="2">Yes</option>
                                </select>
                            </div>
                            
                        </div>
                    </section>
                </form>
            </div>
            <div class="pull-right">
                <button type="button" class="btn btn-primary">Register Customer</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->