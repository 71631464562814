import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'customers',
    loadChildren: () => import('../../components/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: "Customers"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'used-cars',
    loadChildren: () => import('../../components/used-cars/used-cars.module').then(m => m.UsedCarsModule),
    data: {
      breadcrumb: "Used Cars"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'new-cars',
    loadChildren: () => import('../../components/new-cars/new-cars.module').then(m => m.NewCarsModule),
    data: {
      breadcrumb: "New Cars"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'aircrafts',
    loadChildren: () => import('../../components/aircrafts/aircrafts.module').then(m => m.AircraftsModule),
    data: {
      breadcrumb: "Aicrafts"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'houses',
    loadChildren: () => import('../../components/houses/houses.module').then(m => m.HousesModule),
    data: {
      breadcrumb: "Houses"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'yachts',
    loadChildren: () => import('../../components/yachts/yachts.module').then(m => m.YachtsModule),
    data: {
      breadcrumb: "Yachts"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'jewellery',
    loadChildren: () => import('../../components/jewellery/jewellery.module').then(m => m.JewelleryModule),
    data: {
      breadcrumb: "Jewellery"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'needs-analysis',
    loadChildren: () => import('../../components/needs-analysis/needs-analysis.module').then(m => m.NeedsAnalysisModule),
    data: {
      breadcrumb: "Needs Analysis"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'transact',
    loadChildren: () => import('../../components/transact/transact.module').then(m => m.TransactModule),
    data: {
      breadcrumb: "Transact"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'statistics',
    loadChildren: () => import('../../components/statistics/statistics.module').then(m => m.StatisticsModule),
    data: {
      breadcrumb: "Statistics"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'auction',
    loadChildren: () => import('../../components/auction/auction.module').then(m => m.AuctionModule),
    data: {
      // breadcrumb: "Auction"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'store',
    loadChildren: () => import('../../components/parts-and-accessories/parts-and-accessories.module').then(m => m.PartsAndAccessoriesModule),
    data: {
      // breadcrumb: "Parts & Accessories"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'dealer',
    loadChildren: () => import('../../components/dealer/dealer.module').then(m => m.DealerModule),
    data: {
      breadcrumb: "Dealer"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'battery-maintenance',
    loadChildren: () => import('../../components/battery-maintenance/battery-maintenance.module').then(m => m.BatteryMaintenanceModule),
    data: {
      // breadcrumb: "Battery Maintenance"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'it-help',
    loadChildren: () => import('../../components/it-help/it-help.module').then(m => m.ItHelpModule),
    data: {
      // breadcrumb: "Battery Maintenance"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'communications',
    loadChildren: () => import('../../components/communications/communications.module').then(m => m.CommunicationsModule),
    data: {
      breadcrumb: "Communications"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'requests-management',
    loadChildren: () => import('../../components/requests-management/requests-management.module').then(m => m.RequestsManagementModule),
    data: {
      breadcrumb: "Requests"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'bookings-management',
    loadChildren: () => import('../../components/bookings-management/bookings-management.module').then(m => m.BookingsManagementModule),
    data: {
      breadcrumb: "Bookings"
    }
  },
  {
    // TODO: TUMI ADDED FROM HERE
    path: 'finance-apps',
    loadChildren: () => import('../../components/finance-apps/finance-apps.module').then(m => m.FinanceAppsModule),
    data: {
      breadcrumb: "Finance"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  }
];