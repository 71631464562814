export class categoryDB {
    static category = [
        {
            img: "<img src='assets/images/dashboard/product/1.jpg' class='imgTable'>",
            product_name: "Headphones",
            price: "R20.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/2.jpg' class='imgTable'>",
            product_name: "Honor Mobile",
            price: "R462.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/3.jpg' class='imgTable'>",
            product_name: "Samsung LED TV",
            price: "R652.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/4.jpg' class='imgTable'>",
            product_name: "Motorola Bluetooth",
            price: "R25.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/5.jpg' class='imgTable'>",
            product_name: "Apple 6s",
            price: "R782.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/6.jpg' class='imgTable'>",
            product_name: "Printer",
            price: "R4825.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/7.jpg' class='imgTable'>",
            product_name: "Canon Camera",
            price: "R2461.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/8.jpg' class='imgTable'>",
            product_name: "High Quality Headphones",
            price: "R761.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/9.jpg' class='imgTable'>",
            product_name: "Home Theater Speakers",
            price: "R672.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/10.jpg' class='imgTable'>",
            product_name: "Diamond Ring",
            price: "R237.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Jewellery",
        },
        {
            img: "<img src='assets/images/dashboard/product/11.jpg' class='imgTable'>",
            product_name: "Diamond Nacklace",
            price: "R3579.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Jewellery",
        },
        {
            img: "<img src='assets/images/dashboard/product/12.jpg' class='imgTable'>",
            product_name: "Diamond Earrings",
            price: "R3145.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Jewellery",
        },
        {
            img: "<img src='assets/images/dashboard/product/13.jpg' class='imgTable'>",
            product_name: "Night lamp",
            price: "R84.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "furniture",
        },
        {
            img: "<img src='assets/images/dashboard/product/14.jpg' class='imgTable'>",
            product_name: "men's shoes",
            price: "R67.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "shoes",
        },
        {
            img: "<img src='assets/images/dashboard/product/15.jpg' class='imgTable'>",
            product_name: "Ledi's red top",
            price: "R234.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "clothes",
        },
        {
            img: "<img src='assets/images/dashboard/product/16.jpg' class='imgTable'>",
            product_name: "latest ledis shoes",
            price: "R357.00",
            status: "<i class='fa fa-circle font-danger f-12'></i>",
            category: "shoes",
        },
        {
            img: "<img src='assets/images/dashboard/product/17.jpg' class='imgTable'>",
            product_name: "Woman one pis",
            price: "R682.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "clothes",
        },
        {
            img: "<img src='assets/images/dashboard/product/18.jpg' class='imgTable'>",
            product_name: "Mouse",
            price: "R24.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/19.jpg' class='imgTable'>",
            product_name: "Coffee maker",
            price: "R9721.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/20.jpg' class='imgTable'>",
            product_name: "Diamond Nacklace",
            price: "R3579.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Jewellery",
        },

    ]
}
