import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerRegisterComponent } from './customer-register/customer-register.component';
import { CustomersRoutingModule } from './customers-routing.module';
import { CustomerAccountsComponent } from './customer-accounts/customer-accounts.component';
import { CustomerVehiclesComponent } from './customer-vehicles/customer-vehicles.component';
import { NeedsAnalysisComponent } from './needs-analysis/needs-analysis.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    CustomerRegisterComponent,
    CustomerAccountsComponent,
    CustomerVehiclesComponent,
    NeedsAnalysisComponent,
    DialogComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MatDialogModule,
    MaterialModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    CustomersRoutingModule,
    FormsModule,
    MatRadioModule
  ]
})
export class CustomersModule { }
