<!-- Container-fluid starts-->
<!-- TODO: CONNECT DATA - EDIT PROFILE -->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img [src]="profilePicture ? profilePicture : 'assets/images/dashboard/man.png'" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{firstName}}</h5>
                        <!-- <div class="social">
                            <div class="form-group btn-showcase">
                                <button class="btn social-btn btn-fb d-inline-block">
                                    <i class="fa fa-facebook"></i></button>
                                <button class="btn social-btn btn-twitter d-inline-block"><i
                                        class="fa fa-google"></i></button>
                                <button class="btn social-btn btn-google d-inline-block mr-0"><i
                                        class="fa fa-twitter"></i></button>
                            </div>
                        </div> -->
                    </div>
                    <br>
                    <!-- <hr> -->
                    <table class="table table-responsive  mb-0 text-center">
                        <tbody>
                            <tr>
                                <td>Dealership:</td>
                                <td>{{dealership}}</td>
                            </tr>
                            <tr>
                                <td>Occupation:</td>
                                <td>{{occupation}}</td>
                            </tr>
                            <tr>
                                <td>Department:</td>
                                <td>{{department}}</td>
                            </tr>
                            <tr>
                                <td>Designation:</td>
                                <td>{{designation}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="project-status">
                        <h5 class="f-w-600">Employee Status</h5>
                        <div class="media">
                            <div class="media-body">
                                <h6>Performance<span class="pull-right">80%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-primary" role="progressbar" style="width: 90%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Overtime <span class="pull-right">60%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Leaves taken<span class="pull-right">70%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 70%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab title="Account">
                            <ng-template ngbTabContent>                                                                                                                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                        aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
                                        <h4>Account Details</h4>
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4">
                                                Username</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom0"
                                                type="text" placeholder="{{userName}}" disabled>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-xl-3 col-md-4">
                                                Name</label>
                                            <input class="form-control col-xl-8 col-md-7"
                                                type="text" placeholder="{{firstName}}" disabled>
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"> 
                                                Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom4"
                                                type="password" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom5" class="col-xl-3 col-md-4"> 
                                                Confirm Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom5"
                                                type="password" required="">
                                        </div>
                                    </form>
                                </div>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-secondary m-r-10">Cancel</button>
                                    <button type="button" class="btn btn-primary">Save</button>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                                <div class="account-setting">
                                    <h5 class="f-w-600">Notifications</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="chk-ani">
                                                <input class="checkbox_animated" id="chk-ani" type="checkbox">
                                                Allow Desktop Notifications
                                            </label>
                                            <label class="d-block" for="chk-ani1">
                                                <input class="checkbox_animated" id="chk-ani1" type="checkbox">
                                                Enable Notifications
                                            </label>
                                            <label class="d-block" for="chk-ani2">
                                                <input class="checkbox_animated" id="chk-ani2" type="checkbox">
                                                Get notification for my own activity
                                            </label>
                                            <label class="d-block mb-0" for="chk-ani3">
                                                <input class="checkbox_animated" id="chk-ani3" type="checkbox"
                                                    checked="">
                                                DND
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="account-setting deactivate-account">
                                    <h5 class="f-w-600">Deactivate Account</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="edo-ani">
                                                <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani"
                                                    checked="">
                                                I have a privacy concern
                                            </label>
                                            <label class="d-block" for="edo-ani1">
                                                <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                                This is temporary
                                            </label>
                                            <label class="d-block mb-0" for="edo-ani2">
                                                <input class="radio_animated" id="edo-ani2" type="radio" name="rdo-ani"
                                                    checked="">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary">Deactivate Account</button>
                                </div>
                                <div class="account-setting deactivate-account">
                                    <h5 class="f-w-600">Delete Account</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="edo-ani3">
                                                <input class="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1"
                                                    checked="">
                                                No longer usable
                                            </label>
                                            <label class="d-block" for="edo-ani4">
                                                <input class="radio_animated" id="edo-ani4" type="radio"
                                                    name="rdo-ani1">
                                                Want to switch on other account
                                            </label>
                                            <label class="d-block mb-0" for="edo-ani5">
                                                <input class="radio_animated" id="edo-ani5" type="radio" name="rdo-ani1"
                                                    checked="">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary">Delete Account</button>
                                </div>
                            </ng-template>
                        </ngb-tab> -->
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->