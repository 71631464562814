import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Constants } from '../shared/utils/Constants';

@Injectable({
  providedIn: 'root'
})
export class FormService implements ErrorStateMatcher {

  constructor() { }

  customValidator(validatorName: string, validationFunction: (value: any) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const triggerValidation = validationFunction(control.value);
      return triggerValidation ? JSON.parse(`{ "${validatorName}": { "condition": true } }`) : null;
    };
  }

  // Validate form instance
  // check_dirty true will only emit errors if the field is touched
  // check_dirty false will check all fields independent of
  // being touched or not. Use this as the last check before submitting
  public validateForm(formToValidate: FormGroup, formErrors: any, checkDirty?: boolean) {
    const form = formToValidate;
    for (const field in formErrors) {
      if (field) {
        formErrors[field] = '';
        const control: any = form.get(field);
        if (Array.isArray(control.controls)) {
          continue;
        }
        const messages = Constants.validationMessages;
        if (control && control.invalid) {
          if (!checkDirty || (control.dirty || control.touched)) {
            for (const key of Object.keys(control.errors)) {
              if (!Array.isArray(control.controls)) {
                if (key) {
                  formErrors[field] = formErrors[field] || messages[key];
                } else {
                  formErrors[field] = formErrors[field] || messages[key](control.errors[key]);
                }
                if (key === 'maxlength' || key === 'minlength') {
                  const messageSplit = formErrors[field].split(' ');
                  messageSplit.push(control.errors[key].requiredLength);
                  formErrors[field] = messageSplit.join(' ');
                }
                if (key === 'mobileNumber') {
                  formErrors[field] = formErrors[field].replace(':count', control.errors.mobileNumber.required);
                }
                if (key === 'max' && formErrors[field]) {
                  formErrors[field] = formErrors[field] + ' ' + control.errors[key].max;
                }
                if (key === 'rsaId') {
                  formErrors[field] = formErrors[field];
                }
                if (key === 'passwordsMustMatch') {
                  formErrors[field] = formErrors[field];
                }
              }
            }
          }
        }
      }
    }
    return formErrors;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
