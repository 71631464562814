import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { DoctorAccountService } from 'src/app/shared/service/doctors.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">View</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  constructor(private router: Router) {}
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    // console.log(this.rowData.id );
    // TODO: VIEW PAGE
    this.router.navigate(['/customers/customer-register'], { queryParams: { customerId: this.rowData.id, view: 1 } });
  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonTwoViewComponent implements ViewCell, OnInit {
  constructor(
    private router: Router,
  ) {
  }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    this.router.navigate(['/customers/customer-register'], { queryParams: { customerId: this.rowData.id } });
  }
}


@Component({
  selector: 'app-customer-accounts',
  templateUrl: './customer-accounts.component.html',
  styleUrls: ['./customer-accounts.component.scss']
})
export class CustomerAccountsComponent implements OnInit {

  public transactions: any;
  dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;

  constructor(
    private doctorAccountsService: DoctorAccountService,
  ) {
    // TODO: Change TransactionDB to Customer Accounts DB
    doctorAccountsService
      .getByDealerId(this.dealerId)
      .subscribe((data) => {
        this.transactions = new LocalDataSource(data);
      });
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      name: {
        title: 'Name', filter: false
      },
      contact: {
        title: 'Contact', filter: false
      },
      id_number: {
        title: 'ID Number', filter: false
      },
      designation: {
        title: 'Designation', filter: false,
        type: 'html',
      },
      // TODO: CHANGE BOTH COLUMNS TO ACTION BUTTONS
      viewButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
          // this.router.navigate(['/customers/customer-register']);
        },
      },
      editButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonTwoViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`That IS ${row.id}!`)
          });
        },
      },
    },
  };

  ngOnInit(): void {
  }

  onSearchCell(query: string = '') {
    if (query === '') {

      this.transactions.setFilter([]);
      return;

    }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'contact',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  onSearchID(query: string = '') {
    if (query === '') {

      this.transactions.setFilter([]);
      return;

    }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'id_number',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}
