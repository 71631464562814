import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { NeedsAnalysesService } from 'src/app/shared/service/needsAnalyses.service';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Placeholder</button>
  `,
})
export class ButtonTwoViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Placeholder</button>
  `,
})
export class ButtonThreeViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
  }
}
@Component({
  selector: 'app-my-clients',
  templateUrl: './my-clients.component.html',
  styleUrls: ['./my-clients.component.scss']
})
export class MyClientsComponent implements OnInit {
  

  public transactions: any;

  constructor(
    private needsAnalysisService : NeedsAnalysesService,
    private datePipe: DatePipe ) {
    // TODO: CHANGE TO MY CLIENTS / NEEDS ANALYSIS
    const userId = JSON.parse(localStorage.getItem('currentUser')).id;
    needsAnalysisService
    .getByAllocationId(userId)
    .subscribe((data) => {
      this.transactions = new LocalDataSource(data);
    });
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      deal_status: {
        title: 'Deal Status',
        valuePrepareFunction: (dealstatus, i) => {
          if(i.deal_status){
            return i.deal_status
          } else {
            return 'pending'
          }
        },
      },
      make: {
        title: 'Make',
        type: 'html',
        valuePrepareFunction: (usednew, i) => {
          if(i.used_car){
            return i.used_car.model;
          } else if(i.new_car){
            return i.new_car.model
          } else {
            return i.model
          }
        },
      },
      models: {
        title: 'Model',
        type: 'html',
        valuePrepareFunction: (usednew, i) => {
          if(i.used_car){
            return i.used_car.model;
          } else if(i.new_car){
            return i.new_car.model
          } else {
            return i.model
          }
        },
      },
      doctor_account: {
        title: 'Customer Name',
        type: 'html',
        valuePrepareFunction: (doctor_account, i) => {
          if(i.doctor_account){
            return i.doctor_account?.name
          } else {
            return 'Sys Err'
          }
        },
      },
      user_idnum: {
        title: 'ID Number', filter: false
      },
      date: {
        title: 'Date', filter: false,
        valuePrepareFunction: (date) => { 
          if (date){
            var raw = new Date(date);
    
            var formatted = this.datePipe.transform(raw, 'dd MMM yyyy');
            return formatted; 
          } else {
            return date
          }
        }
      },
      next_appointment: {
        title: 'Next Appointment', filter: false,
        valuePrepareFunction: (date, i) => { 
          if (i.next_appointment){
            var raw = new Date(date);
            var formatted = this.datePipe.transform(raw, 'dd MMM yyyy');
            return formatted; 
          } else {
            return 'N/A'
          }
        }
      },
      // editButton: {
      //   title: 'Action',
      //   filter: false,
      //   type: 'custom',
      //   renderComponent: ButtonOneViewComponent,
      //   onComponentInitFunction(instance) {
      //     instance.customButton.subscribe(row => {
      //       alert(`THIS IS ${row.id}!`)
      //     });
      //   },
      // },
      // deleteButton: {
      //   title: '?',
      //   filter: false,
      //   type: 'custom',
      //   renderComponent: ButtonTwoViewComponent,
      //   onComponentInitFunction(instance) {
      //     instance.customButton.subscribe(row => {
      //       alert(`Tell Developers what the button should be`)
      //     });
      //   },
      // },
      soldButton: {
        title: '?',
        filter: false,
        type: 'custom',
        renderComponent: ButtonThreeViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            alert(`Tell Developers what the button should be`)
          });
        },
      },
    },
  };

  ngOnInit(): void {
  }

  onSearchCell(query: string = '') {
    if (query === '') {
            
      this.transactions.setFilter([]);
      return ;
      
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'contact',
        search: query
      }
    ], false); 
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  onSearchID(query: string = '') {
    if (query === '') {
            
      this.transactions.setFilter([]);
      return ;
      
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'id_number',
        search: query
      }
    ], false); 
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}