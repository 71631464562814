import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { IDoctorAccount } from 'src/app/shared/interfaces/DoctorsAccount';
import { DoctorAccountService } from 'src/app/shared/service/doctors.service';
import { NeedsAnalysesService } from 'src/app/shared/service/needsAnalyses.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'app-needs-analysis-stats-targets',
  templateUrl: './needs-analysis-stats-targets.component.html',
  styleUrls: ['./needs-analysis-stats-targets.component.scss']
})
export class NeedsAnalysisStatsTargetsComponent implements OnInit {

  constructor(private needsService: NeedsAnalysesService, private doctorAccountsService: DoctorAccountService) {
    // TODO: Change TransactionDB to Customer Accounts DB
    needsService
    .getAll()
    .subscribe((data: any) => {
      if (data) {
        data.forEach(element =>
          doctorAccountsService
          .getByDealerId(element.dealer_id)
          .subscribe((x) => {

            const object = x;
            this.arr.push(object);
            this.transactions = new LocalDataSource(this.arr);
          })
        );
      }
    });
  }

  public transactions: any;
  public arr = [];
  public settings = {
    hideSubHeader: true,
    actions: {
      date: {
        name: 'amountaaaaa',
        title: '<i class="ion-document" title="YourAction"></i>'
      },
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      name: {
        title: 'Name', filter: false
      },
      contact: {
        title: 'Contact', filter: false
      },
      id_number: {
        title: 'ID Number', filter: false
      },
      designation: {
        title: 'Designation', filter: false,
        type: 'html',
      },
      // TODO: CHANGE COLUMNS TO ACTION BUTTONS
      date: {
        title: 'Action', filter: false
      },
      amount: {
        title: 'Action', filter: false
      }
    },
  };
  ngOnInit(): void {
  }
  onSearchCell(query: string = '') {
    if (query === '') {

      this.transactions.setFilter([]);
      return ;
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'contact',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  onSearchID(query: string = '') {
    if (query === '') {
      this.transactions.setFilter([]);
      return ;
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'id_number',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}
