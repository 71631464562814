import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../_services/form.service';
import { DoctorAccountService } from '../../shared/service/doctors.service';
import { TitleCasePipe } from '@angular/common';
import { DoctorAccount, IDoctorAccount } from '../../shared/interfaces/DoctorsAccount';
import { DropDownConstants } from '../../shared/utils/Constants';
import { mobileNumberValidator } from '../../shared/utils/validators';
import { NeedsAnalysesService } from '../../shared/service/needsAnalyses.service';
import { DryService } from '../../shared/service/dry.service';
import { NewCarService } from '../../shared/service/newCars.service';
import { UsedCarService } from '../../shared/service/usedCars.service';
import { NeedsAnalysisFormError, NeedsAnalysisUserFormErrors } from '../../shared/interfaces/NeedsAnalyses';
import { fromEvent, Observable, of, pipe } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-needs-analysis',
  templateUrl: './needs-analysis.component.html',
  styleUrls: ['./needs-analysis.component.scss']
})
export class NeedsAnalysisComponent implements OnInit, AfterViewInit {
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public needsForm: FormGroup;
  public userForm: FormGroup;
  public resultFound!: boolean;
  public showDepositInfo = false;
  public showTradeInForm = false;
  public showFinancedBy = false;
  public hideUserForm = false;
  public carExists = false;
  public vehicleType = DropDownConstants.vehicleType;
  public vehicleShape = DropDownConstants.vehicleShape;
  public engineSize = DropDownConstants.engineSize;
  public transmission = DropDownConstants.transmission;
  public fuelType = DropDownConstants.fuelType;
  public yesNo = DropDownConstants.yesNo;
  public devices = DropDownConstants.devices;
  public provinces = DropDownConstants.provinces;
  public designations = DropDownConstants.designations;
  public usedNewDropDown = DropDownConstants.usedNew;
  public usedNew;
  public vehicleList = [];
  public needsFormErrors = new NeedsAnalysisFormError();
  public userFormErrors = new NeedsAnalysisUserFormErrors();
  public newCarSearch$: Observable<any>;
  public searching = false;
  @ViewChild('newCar', {static: true}) newCarInput: ElementRef;
  @ViewChild('newOrUsed', {static: true}) newUsedCarSelect: ElementRef;


  public userDetails = new DoctorAccount();
  public searchParam = '';
  public dealerId: string;
  public allocationAccountId: string;
  carExistsValue = '';
  vehicleSearchPlaceholder = 'Click here to search.';


  constructor(private formService: FormService, private formBuilder: FormBuilder,
              private titleCase: TitleCasePipe,
              private zone: NgZone,
              private dryService: DryService,
              private newCarService: NewCarService,
              private usedCarService: UsedCarService,
              private needsService: NeedsAnalysesService,
              private doctorService: DoctorAccountService) {
    // TODO: Change TransactionDB to Customer Accounts DB
    this.dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    this.allocationAccountId = JSON.parse(localStorage.getItem('currentUser')).id;
  }

  ngOnInit(): void {
    this.createUserForm();
    this.createNeedsAnalysisForm();
  }

  ngAfterViewInit() {
    this.newCarSearch$ = fromEvent(this.newCarInput.nativeElement, 'keyup');
    this.newCarSearch$.pipe(
      map(event => event.target.value),
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(text => {
        if (this.usedNew === 'New') {
          if (text === '') {
            return this.newCarService.getAll()
              .pipe(
                tap(() => this.searching = false),
                catchError(() => of([]))
              );
          }
          return this.newCarService.carSearch(text)
            .pipe(
              tap(() => this.searching = false),
              catchError(() => of([])
              ));
        }
        if (this.usedNew === 'Used') {
          if (text === '') {
            return this.usedCarService.getAll()
              .pipe(
                tap(() => this.searching = false),
                catchError(() => of([]))
              );
          }
          return this.usedCarService.carSearch(text)
            .pipe(
              tap(() => this.searching = false),
              catchError(() => of([]))
            );
        }
      })
    ).subscribe(result => {
      this.zone.run(() => {
        this.vehicleList = [];
        if (result && result.length > 0) {
          result.forEach( car => {
            const temp = { name: car.brand + ' - ' + car.model + ' - ' + car.color, value: car.id };
            this.vehicleList.push(temp);
          });
          this.vehicleSearchPlaceholder = 'Click here to search.';
        } else {
          this.vehicleSearchPlaceholder = 'No results found.';
          const temp = { name: 'No results found', value: '' };
          this.vehicleList.push(temp);
        }
      });
    });
  }

  private createUserForm() {
    this.userForm = this.formBuilder.group({
      add_vehicle: [''],
      pro_pic: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      password: ['1234'],
      verify_password: ['1234'],
      contact: ['', [Validators.required, Validators.minLength(10), mobileNumberValidator(10)]],
      email: ['', [Validators.required, Validators.email]],
      id_number: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      street: [''],
      building: [''],
      suburb: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      work_street: [''],
      work_building: [''],
      work_suburb: [''],
      work_city: [''],
      work_province: [''],
      work_p_code: [''],
      kin: [''],
      kin_relation: [''],
      kin_contact: [''],
      gross_income: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      net_income: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      designation: ['', Validators.required],
      device_type: ['', Validators.required],
      practice_no: [''],
      bank_name: [''],
      bank_acc_no: [''],
      bank_acc_type: [''],
      bank_acc_holder: [''],
      branch: [''],
      branch_code: [''],
      reg_fee: [0],
      accepted_terms: [''],
      signature: [''],
      register_date: new Date(),
      company: [''],
      company_reg: [''],
      company_tel: [''],
      dealer_id: [this.dealerId],
      device: [''],
      last_login: [''],
      reg_status: ['incomplete']
    });
    this.userForm.valueChanges.subscribe(() => {
      this.userFormErrors = this.formService.validateForm(this.userForm, this.userFormErrors);
    });
  }
  private createNeedsAnalysisForm() {
    this.needsForm = this.formBuilder.group({
      make: ['', [Validators.required]],
      model: ['', [Validators.required]],
      colour: ['', []],
      colour2: ['', []],
      colour3: ['', []],
      manufacture_type: ['', [Validators.required]],
      park_distance: [false],
      sports: [false],
      sunroof: [false],
      smash_protect: [false],
      features: ['', []],
      engine: ['', []],
      transmission: ['', [Validators.required]],
      fuel: ['', [Validators.required]],
      deposit: ['', [Validators.required]],
      deposit_amnt: [''],
      installment: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      trading: ['', [Validators.required]],
      vehicle_trd: ['', []],
      trade_year: ['', [Validators.pattern(/^[0-9]*$/)]],
      trade_vin: ['', []],
      trade_engine_no: ['', []],
      trade_engine_size: ['', []],
      trade_fuel_type: ['', []],
      trade_transmission: ['', []],
      trade_type: ['', []],
      trade_colour: ['', []],
      trade_kilos: ['', []],
      trade_finance: ['', []],
      trade_notes: ['', []],
      user: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      user_idnum: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      date: [new Date()],
      dealer_id: [this.dealerId],
      deal_status: ['', []],
      allocation: [this.allocationAccountId],
      rating: ['', []],
      review: ['', []],
      notes: ['', []],
      next_appointment: ['', []],
      app_time: ['', []],
      vehicle_id: [''],
      used_new: ['']
    });
    this.needsForm.valueChanges.subscribe(() => {
      this.needsFormErrors = this.formService.validateForm(this.needsForm, this.needsFormErrors);
    });
  }

  searchUser(idNumber: string) {
    this.doctorService.searchByIdNumber(idNumber)
      .subscribe((res) => {
        this.resultFound = true;
        const result = Object.assign({}, res);
        result.province = this.titleCase.transform(res.province);
        result.work_province = this.titleCase.transform(res.work_province);
        result.password = '';
        this.userDetails = result;
        this.userForm.patchValue(result);
        this.needsForm.patchValue({ user_idnum: result.id_number, user: result.id });
      });
  }

  useAccount() {
    if (this.userForm.valid) {
      this.hideUserForm = true;
    }
  }

  registerCustomer() {
    this.needsForm.markAllAsTouched();
    this.userForm.markAllAsTouched();
    this.userFormErrors = this.formService.validateForm(this.userForm, this.userFormErrors);
    if (this.needsForm.valid) {
      const data = Object.assign({}, this.needsForm.value);
      this.submitNeedsForm(data);
    } else if (this.needsForm.get('user_idnum').invalid && this.userForm.valid) {
      this.needsFormErrors = this.formService.validateForm(this.needsForm, this.needsFormErrors);
      const data = Object.assign({}, this.userForm.value);
      if (data.verify_password) {
        delete data.verify_password;
      }
      if (data.add_vehicle) {
        delete data.add_vehicle;
      }
      this.doctorService.create(data)
        .subscribe((result) => {
            this.needsForm.patchValue({ user_idnum: result.id_number, user: result.id });
            if (this.needsForm.valid) {
              const needsData = Object.assign({}, this.needsForm.value);
              this.submitNeedsForm(needsData);
            }
          },
          () => {
            this.dryService.presentToast('Customer registration failed.');
          });
    } else {
      this.needsFormErrors = this.formService.validateForm(this.needsForm, this.needsFormErrors);
    }
  }

  private submitNeedsForm(data) {
    this.needsService.create(data).subscribe((result) => {
      this.dryService.presentToast('Record created successfully');
      this.needsForm.reset();
      this.userForm.reset();
      this.searchParam = '';
      this.resultFound = false;
      this.userDetails = new DoctorAccount();
      this.usedNew = '';
      this.carExistsValue = '';
      this.vehicleList = [];
      this.carExists = false;
      this.hideUserForm = false;
      this.showDepositInfo = false;
      this.showTradeInForm = false;
      this.showFinancedBy = false;
      window.scroll(0, 0);
    });
  }

  depositChangeEvent($event: any) {
    const showDetails = $event === 'Yes';
    // this.updateForm(showDetails);
    this.showDepositInfo = showDetails;
  }

  private updateForm(showDetails: boolean) {
  }

  tradeInChangeEvent($event: any) {
    const showDetails = $event === 'Yes';
    // this.updateForm(showDetails);
    this.showTradeInForm = showDetails;
  }
  financedByChangeEvent($event: any) {
    const showDetails = $event === 'Financed';
    // this.updateForm(showDetails);
    this.showFinancedBy = showDetails;
  }

  carExistsChangeEvent($event: any) {
    this.carExists = $event === 'Yes';
    this.needsFormErrors = new NeedsAnalysisFormError();
    this.needsForm.markAsUntouched();
    if (this.carExists) {
      this.vehicleSelectedDisableValidations();
    } else {
      this.vehicleSelectedEnableValidations();
    }
  }

  usedOrNewChangeEvent($event: any) {
    this.vehicleList = [];
    this.needsForm.patchValue({ vehicle_id: '' });
    this.usedNew = $event;
    if ($event === 'New') {
      this.needsForm.patchValue({ used_new: "new" });
      this.newCarService.getAll().subscribe(result => {
        result.forEach( car => {
          const temp = { name: car.brand + ' - ' + car.model + ' - ' + car.color, value: car.id };
          this.vehicleList.push(temp);
        });
      });
    } else if ($event === 'Used') {
      this.needsForm.patchValue({ used_new: "used" });
      this.usedCarService.getAll().subscribe(result => {
        result.forEach( car => {
          const temp = { name: car.brand + ' - ' + car.model + ' - ' + car.color, value: car.id };
          this.vehicleList.push(temp);
        });
      });
    }
  }

  vehicleSelected($event: any) {
    this.needsForm.patchValue({vehicle_id: $event});
    this.vehicleSelectedDisableValidations();
  }

  private vehicleSelectedDisableValidations() {
    this.needsForm.get('fuel').setValidators([]);
    this.needsForm.get('fuel').updateValueAndValidity();
    // this.needsForm.get('engine').setValidators([]);
    // this.needsForm.get('engine').updateValueAndValidity();
    this.needsForm.get('transmission').setValidators([]);
    this.needsForm.get('transmission').updateValueAndValidity();
    // this.needsForm.get('deposit').setValidators([]);
    // this.needsForm.get('deposit').updateValueAndValidity();
    this.needsForm.get('installment').setValidators([Validators.pattern(/^[0-9]*$/)]);
    this.needsForm.get('installment').updateValueAndValidity();
    this.needsForm.get('make').setValidators([]);
    this.needsForm.get('make').updateValueAndValidity();
    this.needsForm.get('model').setValidators([]);
    this.needsForm.get('model').updateValueAndValidity();
    this.needsForm.get('manufacture_type').setValidators([]);
    this.needsForm.get('manufacture_type').updateValueAndValidity();
  }

  private vehicleSelectedEnableValidations() {
    this.needsForm.get('fuel').setValidators([Validators.required]);
    // this.needsForm.get('engine').setValidators([]);
    this.needsForm.get('transmission').setValidators([Validators.required]);
    // this.needsForm.get('deposit').setValidators([]);
    this.needsForm.get('installment').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
    this.needsForm.get('make').setValidators([Validators.required]);
    this.needsForm.get('model').setValidators([Validators.required]);
    this.needsForm.get('manufacture_type').setValidators([Validators.required]);

    this.needsForm.get('manufacture_type').updateValueAndValidity();
    this.needsForm.get('model').updateValueAndValidity();
    this.needsForm.get('make').updateValueAndValidity();
    this.needsForm.get('installment').updateValueAndValidity();
    // this.needsForm.get('deposit').updateValueAndValidity();
    this.needsForm.get('transmission').updateValueAndValidity();
    // this.needsForm.get('engine').updateValueAndValidity();
    this.needsForm.get('fuel').updateValueAndValidity();
  }

  onKeyUp(value: any) {
    console.log('Key up value: ', value);
    // this.newCarService.carSearch(value).subscribe(result => {
    //   this.zone.run(() => {
    //     this.vehicleList = [];
    //     result.forEach( car => {
    //       const temp = { name: car.brand + ' ' + car.model + ' - ' + car.color, value: car.id };
    //       this.vehicleList.push(temp);
    //     });
    //   });
    // });
  }
}
