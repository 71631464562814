<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
        <h5>Search For Customer</h5>
        </div>
        <div class="card-body">
            <form class="needs-validation">
                <div class="col-8">
                    <section>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Customer ID Number</label>
                                <input type="text" class="form-control" >
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="validationCustom0" class="mr-2"> 
                                    Customer Cell Number</label>
                                <input type="text" class="form-control" >
                            </div>
                            <div class="m-b-10 m-l-15">
                                <button class="btn btn-primary" type="button">Search</button>
                            </div>
                        </div>
                    </section>
                </div>
            </form>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <!-- TODO: Page for when booking the test drive -->
                    <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->