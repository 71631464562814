<!-- Container-fluid starts-->
<div class="container-fluid">
    <section>
        <div class="card">
            <div class="card-header">
                <h5>New Sales Executive Targets</h5>
            </div>
            <div class="card-body">
                <form class="needs-validation">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-2 form-group">
                                <label for="validationCustom0">
                                    Year</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="col-sm-2 form-group">
                                <label for="validationCustom0">
                                    Month</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="col-sm-3 form-group">
                                <label for="validationCustom0" class="mr-2">
                                    Filter By Sales Executive</label>
                                <!-- TODO: MAKE SELECT DROPDOWN -->
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="row">
                            <div class="m-t-20 m-l-20">
                                <button class="btn btn-primary" type="button">Filter</button>
                                <button class="btn btn-primary m-l-10" type="button">Clear</button>

                                <button type="button" class="btn btn-secondary m-l-50" data-toggle="modal"
                                    data-original-title="test" data-target="#exampleModal" (click)="open(content)">Add
                                    Target</button>
                                <ng-template #content let-modal>
                                    <div class="modal-header">
                                        <h5 class="modal-title f-w-600" id="exampleModalLabel">Add New Target</h5>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form class="needs-validation">
                                            <div class="form">
                                                <div class="form-group">
                                                    <label for="validationCustom01" class="mb-1">Select Sales Executive:</label>
                                                    <input class="form-control" id="validationCustom01" type="text">
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label for="validationCustom02" class="mb-1">Select Month:</label>
                                                    <input class="form-control" id="validationCustom02" type="text">
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label for="validationCustom02" class="mb-1">Enter Target:</label>
                                                    <input class="form-control" id="validationCustom02" type="text">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary">Submit</button>
                                        <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                            (click)="modal.dismiss('Cross click')">Close</button>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->