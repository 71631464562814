import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JewelleryComponent } from './jewellery/jewellery.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { JewelleryRoutingModule } from './jewellery-routing.module';



@NgModule({
  declarations: [JewelleryComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    JewelleryRoutingModule
  ]
})
export class JewelleryModule { }
