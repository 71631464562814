import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private url = `${environment.appEngineURL}`;

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  create(doctorsAccount): Observable<any> {
    // create Staff Accounts for sign up
    return this.http.post<any>(this.url + '/signup', JSON.stringify(doctorsAccount), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  login(email, password) {
    return this.http.post<any>(this.url + '/login', { email, password, returnSecureToken: true})
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  loginPromise(email, password): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.url + '/login', { email, password, "returnSecureToken": true })
        .subscribe(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          resolve(user);
          return user;
        }, error => {
          reject(error);
        });
    });
  }

  getDealerAccount(): Promise<any> {
    if (localStorage.getItem('currentUser')) {
      const dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
      return new Promise((resolve, reject) => {
        this.http.get<any>(this.url + '/dealerAccounts/' + dealerId)
          .subscribe(response => {
            localStorage.setItem('dealerAccount', JSON.stringify(response));
            resolve(response);
          },
            error => {
              reject(error);
            });
      });
    } else {
      return;
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('dealerAccount');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
