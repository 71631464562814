import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { first } from 'rxjs/operators';
import { DealerStaffAccountsService } from 'src/app/shared/service/dealer-staff-accounts.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';


@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  constructor(private router: Router) { }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    this.router.navigate(['settings/staffaccount/create'], { queryParams: { staffId: this.rowData.id } });

  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Delete</button>
  `,
})
export class ButtonTwoViewComponent implements ViewCell, OnInit {
  constructor(private dealerStaffAccountService: DealerStaffAccountsService,) { }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.dealerStaffAccountService.delete(this.rowData.id).subscribe(res => {
      this.dealerStaffAccountService.reloadCurrentRoute();
    });
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'app-staff-accounts',
  templateUrl: './staff-accounts.component.html',
  styleUrls: ['./staff-accounts.component.scss']
})
export class StaffAccountsComponent implements OnInit {
  public transactions: any;
  public usedCars = [];
  error: any;
  loading: boolean;

  constructor(
     dealerStaffAccount: DealerStaffAccountsService,
  ) {
    // TODO Michael: Change Used Car Services to Dealer Staff Accounts Serice
    // (Only be able to edit and show accounts according to 
    // current logged in dealer and also User permissions(eg: Manager not Regular Employee))
    dealerStaffAccount.getByDealerId()
      .subscribe((data) => this.transactions = new LocalDataSource(data))
  }

  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      media: {
        title: 'Image',
        type: 'html',
        valuePrepareFunction: (media) => {
          const picture = media[0] ? media[0]?.medium?.url : 'assets/images/dashboard/man.png';
          return `<img width="50px" src="${picture}" />`;
        },
      },
      name: {
        title: 'Name', filter: false
      },
      department: {
        title: 'Department', filter: false
      },
      designation: {
        title: 'Designation', filter: false
      },
      email: {
        title: 'Email', filter: false
      },
      contact: {
        title: 'Phone', filter: false,
      },
      // TODO: CHANGE BOTH COLUMNS TO ACTION BUTTONS
      // edit: {
      //   title: 'Action', filter: false
      // },
      // delete: {
      //   title: 'Action', filter: false
      // },
      viewButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
        },
      },
      editButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonTwoViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
        },
      },
    }
  };

  ngOnInit(): void {
    // localStorage.getItem
    // TODO
    // this.UsedCars.getById('15')
    //   .pipe(first())
    //   .subscribe(
    //     (data: any) => {
    //       this.usedCars.push(data);
    //       console.log(this.usedCars);
    //     },
    //     error => {
    //       console.log(error);
    //       this.error = error;
    //     });
  }

  onSearchStatus(query: string = '') {
    if (query === '') {

      this.transactions.setFilter([]);
      return;

    }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}
