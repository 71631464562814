import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsedCarsEnquiriesComponent } from './used-cars-enquiries/used-cars-enquiries.component';
import { UsedCarsComponent } from './used-cars/used-cars.component';
import { UsedCarsRoutingModule } from './used-cars-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';




@NgModule({
  declarations: [UsedCarsEnquiriesComponent, UsedCarsComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsedCarsRoutingModule
  ]
})
export class UsedCarsModule { }
