export class Constants {
  public static validationMessages = {
    required: 'This is a required field',
    email: 'Please enter a valid email address',
    maxlength: 'Length should be',
    minlength: 'Length should be',
    max: 'Value can not be grater than',
    mobileNumber: 'Please enter a valid SA phone number',
    rsaId: 'Please enter a valid SA ID number',
    pattern: 'Incorrect format',
    passwordsMustMatch: 'Passwords must match'
  };
}

export class DropDownConstants {
  public static vehicleMake = [
    { name: 'VW', value: 'VW' },
    { name: 'Audi', value: 'Audi' }
  ];

  public static vehicleModels = {
    audi: [
      { name: 'A1', value: 'A1' },
      { name: 'A2', value: 'A2' },
      { name: 'A3', value: 'A3' },
      { name: 'A4', value: 'A4' },
      { name: 'A5', value: 'A5' },
      { name: 'A6', value: 'A6' },
      { name: 'A7', value: 'A7' },
      { name: 'A8', value: 'A8' },
    ],
    VW: [
      { name: 'Amarok', value: 'Amarok' },
      { name: 'Beetle', value: 'Beetle' },
      { name: 'Polo', value: 'Polo' },
      { name: 'Golf', value: 'Gold' },
      { name: 'Up', value: 'Up' },
      { name: 'Caddy', value: 'Caddy' },
    ]
  };

  public static vehicleType = [
    { name: 'Commercial', value: 'Commercial' },
    { name: 'Passenger', value: 'Passenger' }
  ];

  public static vehicleShape = [
    { name: 'Hatch back', value: 'Hatch back' },
    { name: 'Sedan', value: 'Sedan' },
    { name: 'SUV', value: 'SUV' },
    { name: 'Bakkie', value: 'Bakkie' },
    { name: 'Kombi', value: 'Kombi' }
  ];

  public static transmission = [
    { name: 'Automatic', value: 'Automatic' },
    { name: 'Manual', value: 'Manual' },
    { name: 'DSG', value: 'DSG' }
  ];

  public static fuelType = [
    { name: 'Petrol', value: 'Petrol' },
    { name: 'Diesel', value: 'Diesel' },
    { name: 'Hybrid', value: 'Hybrid' }
  ];

  public static yesNo = [
    { name: 'No', value: 'No' },
    { name: 'Yes', value: 'Yes' }
  ];

  public static usedNew = [
    { name: 'Used Car', value: 'Used' },
    { name: 'New Car', value: 'New' }
  ];

  public static engineSize = [
    { name: '1.0', value: '1.0' },
    { name: '1.1', value: '1.1' },
    { name: '1.2', value: '1.2' },
    { name: '1.3', value: '1.3' },
    { name: '1.4', value: '1.4' },
    { name: '1.5', value: '1.5' },
    { name: '1.6', value: '1.6' },
    { name: '1.7', value: '1.7' },
    { name: '1.8', value: '1.8' },
    { name: '1.9', value: '1.9' },
    { name: '2.0', value: '2.0' },
    { name: '2.1', value: '2.1' },
    { name: '2.2', value: '2.2' },
    { name: '2.3', value: '2.3' },
    { name: '2.4', value: '2.4' },
    { name: '2.5', value: '2.5' },
    { name: '2.6', value: '2.6' },
    { name: '2.7', value: '2.7' },
    { name: '2.8', value: '2.8' },
    { name: '2.9', value: '2.9' },
    { name: '3.0', value: '3.0' },
    { name: '3.1', value: '3.1' },
    { name: '3.2', value: '3.2' },
    { name: '3.3', value: '3.3' },
    { name: '3.4', value: '3.4' },
    { name: '3.5', value: '3.5' },
    { name: '3.6', value: '3.6' },
    { name: '3.7', value: '3.7' },
    { name: '3.8', value: '3.8' },
    { name: '3.9', value: '3.9' },
    { name: '4.0', value: '4.0' },
    { name: '4.1', value: '4.1' },
    { name: '4.2', value: '4.2' },
    { name: '4.3', value: '4.3' },
    { name: '4.4', value: '4.4' },
    { name: '4.5', value: '4.5' },
    { name: '4.6', value: '4.6' },
    { name: '4.7', value: '4.7' },
    { name: '4.8', value: '4.8' },
    { name: '4.9', value: '4.9' },
    { name: '5.0', value: '5.0' },
    { name: '5.1', value: '5.1' },
    { name: '5.2', value: '5.2' },
    { name: '5.3', value: '5.3' },
    { name: '5.4', value: '5.4' },
    { name: '5.5', value: '5.5' },
    { name: '5.6', value: '5.6' },
    { name: '5.7', value: '5.7' },
    { name: '5.8', value: '5.8' },
    { name: '5.9', value: '5.9' },
    { name: '6.0', value: '6.0' },
    { name: '6.1', value: '6.1' },
    { name: '6.2', value: '6.2' },
    { name: '6.3', value: '6.3' },
    { name: '6.4', value: '6.4' },
    { name: '6.5', value: '6.5' },
    { name: '6.6', value: '6.6' },
    { name: '6.7', value: '6.7' },
    { name: '6.8', value: '6.8' },
    { name: '6.9', value: '6.9' },
  ];

  public static designations = [
    { id: 'Normal', name: 'Normal' },
    { id: 'CEO', name: 'CEO' },
    { id: 'Doctor', name: 'Doctor' },
    { id: 'Business Owner', name: 'Business Owner' },
    { id: 'Manager', name: 'Manager' },
  ];

  public static devices = [
    { id: 'Apple IOS', name: 'IOS' },
    { id: 'Google Android', name: 'Android' },
  ];

  public static provinces = [
    { id: '1', name: 'Gauteng' },
    { id: '2', name: 'North West' },
    { id: '3', name: 'KwaZulu Natal' },
    { id: '4', name: 'Limpopo' },
    { id: '5', name: 'Freestate' },
    { id: '6', name: 'Mpumalanga' },
    { id: '7', name: 'Northern Cape' },
    { id: '8', name: 'Western Cape' },
    { id: '9', name: 'Eastern Cape' },
  ];
}
