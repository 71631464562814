<!-- Container-fluid starts-->
<!-- TODO: CONNECT DATA - EDIT PROFILE -->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img [src]="profilePicture ? profilePicture : url[0].img" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                    </div>
                    <br>
                    <div class="align-self-center text-center">
                        <h5 *ngIf="addMode && !view" class="f-w-600 mb-0">Upload Profile Picture</h5>
                        <h5 *ngIf="!addMode" class="f-w-600 mb-0">Profile Picture</h5>
                        <br>
                        <!-- <div class="col-xl-3 xl-50 col-sm-6 col-3">
                            <ul class="file-upload-product">
                                <li *ngFor="let item of url; let i=index">
                                    <div class="box-input-file">
                                        <input class="upload" type="file" multiple (change)="readUrl($event)">
                                        <img class="imgUpload" alt="" [src]="item.img">
                                    </div>
                                </li>
                            </ul>
                        </div>   -->
                        <!-- <div id="hide">
                            <button type="file" class="btn btn-secondary box-input-file">
                                <app-feather-icons [icon]="'upload-cloud'" class=""></app-feather-icons>
                                <input class="upload" type="file" (change)="readUrl($event, 0)">
                            </button>
                        </div> -->
                        <div id="upload_button" *ngIf="addMode && !view">
                            <label>
                                <input type="file" class="upload" (change)="readUrl($event, 0)">
                                <app-feather-icons [icon]="'upload-cloud'" class="btn btn-primary"></app-feather-icons>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        <div class="col-xl-9">
            <div class="card tab2-card">
                <div class="card-body">
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab title="Account">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <section *ngIf="admin && addMode">
                                        <h4 class="font-weight-bold">Select A Dealership To Add The Staff Member For</h4>
                                        <div class="form-group row">
                                            <mat-form-field class="col form-group">
                                                <mat-label for="validationCustom0" class="mr-2">
                                                    Select A Vendor / Dealership</mat-label>
                                                <!-- <mat-select formControlName="province" ng-model="selected"> -->
                                                <mat-select (selectionChange)="onChange($event)">
                                                    <mat-option *ngFor="let province of provinces;" [value]="province">
                                                        {{province.dealer_name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </section>

                                    <form [formGroup]="signupForm" class="needs-validation user-add" novalida>
                                        <h4 *ngIf="manager">Create a new staff account {{dealer_name}}</h4>
                                        <h4 *ngIf="!selected && admin && addMode">Create a new staff account..</h4>
                                        <h4 *ngIf="!addMode">Staff Account for {{dealer_name}}</h4>
                                        <h4 *ngIf="selected">Create a new staff account for {{dealer_name}}</h4>
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4">
                                                Email</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="email"
                                                id="validationCustom0" type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Name</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="name"
                                                type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Username</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="username"
                                                type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Phone</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="contact"
                                                type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Title</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="title"
                                                type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Department</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="department"
                                                type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Designation</label>
                                            <Select type="text" #designation class="form-control col-xl-8 col-md-7"
                                                (change)="designationList(designation.value)">
                                                <option value="" disabled selected>Select designation</option>
                                                <option value="Regular Employee">Regular Employee</option>
                                                <option value="Manager">Manager</option>
                                                <option *ngIf="admin" value="Admin">Admin</option>
                                            </Select>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Occupation</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="occupation"
                                                type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-4">
                                                Password</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="password"
                                                type="password">
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"> 
                                                Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom4"
                                                type="password" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom5" class="col-xl-3 col-md-4"> 
                                                Confirm Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom5"
                                                type="password" required="">
                                        </div> -->
                                    </form>
                                </div>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-secondary m-r-10" (click)="cancel()">Cancel</button>
                                    <button type="button" *ngIf="addMode" class="btn btn-primary"
                                        (click)="signUpAccount()">Create</button>
                                    <button type="button" *ngIf="!addMode && !view" class="btn btn-primary"
                                        (click)="updateAccount()">Save</button>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                                <div class="account-setting">
                                    <h5 class="f-w-600">Notifications</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="chk-ani">
                                                <input class="checkbox_animated" id="chk-ani" type="checkbox">
                                                Allow Desktop Notifications
                                            </label>
                                            <label class="d-block" for="chk-ani1">
                                                <input class="checkbox_animated" id="chk-ani1" type="checkbox">
                                                Enable Notifications
                                            </label>
                                            <label class="d-block" for="chk-ani2">
                                                <input class="checkbox_animated" id="chk-ani2" type="checkbox">
                                                Get notification for my own activity
                                            </label>
                                            <label class="d-block mb-0" for="chk-ani3">
                                                <input class="checkbox_animated" id="chk-ani3" type="checkbox"
                                                    checked="">
                                                DND
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="account-setting deactivate-account">
                                    <h5 class="f-w-600">Deactivate Account</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="edo-ani">
                                                <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani"
                                                    checked="">
                                                I have a privacy concern
                                            </label>
                                            <label class="d-block" for="edo-ani1">
                                                <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                                This is temporary
                                            </label>
                                            <label class="d-block mb-0" for="edo-ani2">
                                                <input class="radio_animated" id="edo-ani2" type="radio" name="rdo-ani"
                                                    checked="">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary">Deactivate Account</button>
                                </div>
                                <div class="account-setting deactivate-account">
                                    <h5 class="f-w-600">Delete Account</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="edo-ani3">
                                                <input class="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1"
                                                    checked="">
                                                No longer usable
                                            </label>
                                            <label class="d-block" for="edo-ani4">
                                                <input class="radio_animated" id="edo-ani4" type="radio"
                                                    name="rdo-ani1">
                                                Want to switch on other account
                                            </label>
                                            <label class="d-block mb-0" for="edo-ani5">
                                                <input class="radio_animated" id="edo-ani5" type="radio" name="rdo-ani1"
                                                    checked="">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary">Delete Account</button>
                                </div>
                            </ng-template>
                        </ngb-tab> -->
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->