import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import { StaffAccountsComponent } from './staff-accounts/staff-accounts.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CreateStaffAccountComponent } from './staff-accounts/create-staff-account/create-staff-account.component';
import { CreateDealerAccountComponent } from './dealer-accounts/create-dealer-account/create-dealer-account.component';
import { DealeraccountsComponent } from './dealer-accounts/dealeraccounts/dealeraccounts.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [
    ProfileComponent,
    StaffAccountsComponent,
    CreateStaffAccountComponent,
    CreateDealerAccountComponent,
    DealeraccountsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    MaterialModule,
    MatDialogModule,
    FormsModule,
    MatRadioModule

  ]
})
export class SettingModule { }
