import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UsedCarEnquiry as UsedCarEnquiry } from '../interfaces/UsedCarEnquiry';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsedCarEnquiriesService {

  private apiServer = environment.appEngineURL;
  // public token = JSON.parse(localStorage.getItem('currentUser')).token;
  public token = ''
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  }
  constructor(private httpClient: HttpClient) { }

  create(usedCarEnquiries: UsedCarEnquiry): Observable<UsedCarEnquiry> {
    return this.httpClient.post<UsedCarEnquiry>(this.apiServer + '/usedCarEnquiries/', JSON.stringify(usedCarEnquiries), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }  
  getById(id): Observable<UsedCarEnquiry[]> {
    return this.httpClient.get<UsedCarEnquiry[]>(this.apiServer + '/usedCarEnquiries/' + id, this.httpOptions)
    .pipe(map((response: any) => response.json()));

    // return this.httpClient.get<UsedCarEnquiries>(this.apiServer + '/usedCarEnquiriess/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    // )
  }

  getByDealerId(id): Observable<UsedCarEnquiry> {
    return this.httpClient.get<UsedCarEnquiry>(this.apiServer + '/usedCarEnquiries/' + id, this.httpOptions)
   .pipe(map((response: any) => response.json()));
  }

  getAll(): Observable<UsedCarEnquiry[]> {
    return this.httpClient.get<UsedCarEnquiry[]>(this.apiServer + '/usedCarEnquiries/', this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id, product): Observable<UsedCarEnquiry> {
    return this.httpClient.put<UsedCarEnquiry>(this.apiServer + '/usedCarEnquiries/' + id, JSON.stringify(product), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    return this.httpClient.delete<UsedCarEnquiry>(this.apiServer + '/usedCarEnquiries/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  errorHandler(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}