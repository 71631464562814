import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from './windows.service';
// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {

  constructor(@Inject(WINDOW) private window) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  public screenWidth: any;
  public collapseSidebar = false;

  DoctorsClubMenu: Menu[] = [
    // 	{
    // 		path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 	},
    {
      title: 'Customers', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/customers/customer-register', title: 'Register A New Customer', icon: 'home', type: 'link', badgeType: 'primary', active: false

        },
        {
          path: '/customers/customer-accounts', title: 'Customer Accounts', icon: 'home', type: 'link', badgeType: 'primary', active: false
        },
        {
          path: '/customers/customer-vehicles', title: 'Customer Vehicles', icon: 'home', type: 'link', badgeType: 'primary', active: false
        },
        // {
        //   path: '/customers/needs-analysis', title: 'Needs Analysis', icon: 'home', type: 'link', badgeType: 'primary', active: false
        // },
      ]
    },
    {
      title: 'New Cars', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/new-cars/new-cars', title: 'New Cars', icon: 'home', type: 'link', badgeType: 'primary', active: false

        },
        {
        	path: '/new-cars/new-cars-enquiries', title: 'New Cars Enquries', icon: 'home', type: 'link', badgeType: 'primary', active: false
        }
      ]
    },
    {
      title: 'Used Cars', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/used-cars/used-cars', title: 'Used Cars', icon: 'home', type: 'link', badgeType: 'primary', active: false

        },
        {
        	path: '/used-cars/used-cars-enquiries', title: 'Used Cars Enquries', icon: 'home', type: 'link', badgeType: 'primary', active: false
        }
      ]
    },
    {
      title: 'Aircrafts', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/aircrafts/aircrafts', title: 'Aircrafts', icon: 'home', type: 'link', badgeType: 'primary', active: false

        },
      ]
    },
    {
      title: 'Houses', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/houses/houses', title: 'Houses', icon: 'home', type: 'link', badgeType: 'primary', active: false

        },
      ]
    },
    {
      title: 'Yachts', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/yachts/yachts', title: 'Yachts', icon: 'home', type: 'link', badgeType: 'primary', active: false
        },
      ]
    },
    {
      title: 'Jewellery', icon: 'box', type: 'sub', active: false, children: [
        {
          path: '/jewellery/jewellery', title: 'Jewellery', icon: 'home', type: 'link', badgeType: 'primary', active: false

        },
      ]
    },
    	{
    		title: 'Needs Analysis', icon: 'box', type: 'sub', active: false, children: [
    			{
    				path: '/needs-analysis/needs-analysis', title: 'Needs Analysis', icon: 'home', type: 'link', badgeType: 'primary', active: false
    			},
    			{
    				path: '/needs-analysis/my-clients', title: 'My Clients', icon: 'home', type: 'link', badgeType: 'primary', active: false
    			},
    			{
    				// TODO RENAME: Monthly Target Board
    				path: '/needs-analysis/all-clients', title: 'All Clients', icon: 'home', type: 'link', badgeType: 'primary', active: false
    			},
    			// {
    			// 	// TODO RENAME: Monthly Target Board
    			// 	path: '/needs-analysis/stats', title: 'Stats & Targets', icon: 'home', type: 'link', badgeType: 'primary', active: false
    			// },
    			// {
    			// 	// TODO RENAME: Monthly Target Board
    			// 	path: '/needs-analysis/view-targets', title: 'View Targets', icon: 'home', type: 'link', badgeType: 'primary', active: false
    			// }
    		]
    	},
    ///////////////////////////////// KEYLOOP INTEGRATION TODO TUMI
    // 	{
    // 		title: 'Transact', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/transact/transact', title: 'Transact', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/transact/book-test-drive', title: 'Book Test Drive', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 			{
    // 				path: '/transact/get-quote', title: 'Get A Quote', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 			{
    // 				path: '/transact/book-service', title: 'Book Service', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 			{
    // 				path: '/transact/book-fault', title: 'Book Fault', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			}
    // 		]
    // 	},
    // 	{
    // 		title: 'Bookings Management', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/bookings-management/test-drive-bookings', title: 'Test Drive Bookings', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/bookings-management/service-bookings', title: 'Service Bookings', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 			{
    // 				path: '/bookings-management/fault-bookings', title: 'Fault Bookings', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 		]
    // 	},
    /////////////////////////////////
    // 	{ // TODO AT THE END
    // 		title: 'Statistics', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/statistics/statistics-summary', title: 'Statistics Summary', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/statistics/filter-stats', title: 'Filter Stats', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			}
    // 		]
    // 	},
    	{
    		path: '/auction/auction', title: 'Auction', icon: 'home', type: 'link', badgeType: 'primary', active: false
    	},
    // 	{ // RANDOM STUFF TO BUY
    // 		path: '/store/store', title: 'Parts & Accessories', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 	},
    // 	{
    // 		title: 'Dealer', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/dealer/stock-sheet', title: 'Stock Sheet', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/dealer/ratings', title: 'Ratings', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			}
    // 		]
    // 	},
    // 	{
    // 		path: '/battery-maintenance/battery-maintenance', title: 'Battery Maintenance', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 	},
    // 	{ // COMPOSE AN EMAIL LOCALLY
    // 		path: '/it-help/it-help', title: 'I.T Help ', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 	},
    // 	{ // SEND MESSAGES TO OTHER USERS / DEALERS / NOTIFICATIONS SYSTEM
    // 		title: 'Communications', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/communications/send-message', title: 'Send Notification Message	', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/communications/my-messages', title: 'My Messages', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 			{
    // 				path: '/communications/social-posts', title: 'Social Posts', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			},
    // 		]
    // 	},
    // 	{ // ASK THANDO - NOT DONE AT ALL
    // 		title: 'Requests Management', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/requests-management/quote-requests', title: 'Quote Requests', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/requests-management/evaluation-requests', title: 'Evaluation Requests', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			}
    // 		]
    // 	},
    // 	{ NOT DONE AT ALL
    // 		title: 'Finance Apps', icon: 'box', type: 'sub', active: false, children: [
    // 			{
    // 				path: '/finance-apps/business', title: 'Business Finance Apps', icon: 'home', type: 'link', badgeType: 'primary', active: false

    // 			},
    // 			{
    // 				path: '/finance-apps/personal', title: 'Personal Finance Apps', icon: 'home', type: 'link', badgeType: 'primary', active: false
    // 			}
    // 		]
    // 	},
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.DoctorsClubMenu);

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }


}
