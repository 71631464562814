<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary">
                        <!-- <div class="svg-icon"> -->
                            <img class="img-100 logo-wrapper " src="assets/OriginalDCIcon.webp" alt="#">
                        <!-- </div> -->
                        <div class="single-item">
                            <owl-carousel-o [options]="owlcarouselOptions">
                                <ng-container *ngFor="let data of owlcarousel">
                                    <ng-template carouselSlide class="item">
                                        <div>
                                            <h3>{{data.title}}</h3>
                                            <p>{{data.desc}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Login</ng-template>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm" 
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" formControlName="username" type="email"
                                                        class="form-control" placeholder="Username"
                                                        id="exampleInputEmail1">
                                                </div>
                                                <div class="form-group">
                                                    <input required="" formControlName="password" type="password"
                                                        class="form-control" placeholder="Password">
                                                </div>
                                                <!-- <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Remember me</label>
                                                        <a href="javascript:void(0)"
                                                            class="btn btn-default forgot-pass">lost your
                                                            password</a>
                                                    </div>
                                                </div> -->
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit" (click)="onSubmit()">Login</button>
                                                </div>
                                                <!-- <div class="form-footer">
                                                    <span>Or Login up with social platforms</span>
                                                    <ul class="social">
                                                        <li><a class="icon-facebook" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-twitter" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                                <!-- <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-unlock mr-2"></i> Register
                                        <ng-template ngbTabContent>
                                            <form [formGroup]="registerForm" 
                                                class="form-horizontal auth-form" novalidate>
                                                <div class="form-group">
                                                    <input required="" name="login[username]" type="email"
                                                        class="form-control" placeholder="Username"
                                                        id="exampleInputEmail12">
                                                </div>
                                                <div class="form-group">
                                                    <input required="" name="login[password]" type="password"
                                                        class="form-control" placeholder="Password">
                                                </div>
                                                <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing1">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing1"><span>I agree all statements
                                                                in <a href="javascript:void(0)" class="pull-right">Terms
                                                                    &amp;
                                                                    Conditions</a></span></label>
                                                    </div>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit">Register</button>
                                                </div>
                                                <div class="form-footer">
                                                    <span>Or Sign up with social platforms</span>
                                                    <ul class="social">
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-facebook"></i></a></li>
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-twitter"></i></a></li>
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-instagram"></i></a></li>
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-pinterest"></i></a></li>
                                                    </ul>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </ng-template>
                                </ngb-tab> -->
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>