export class productDB {
    static product = [
        {
            img: "assets/images/electronics/product/1.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        },
        {
            img: "assets/images/electronics/product/1.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"on sale",
            tag:"new"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Car",
            discount: "R500.00",
            price: "R600.00",
            sale:"not on sale",
            tag:"old"
        }
    ]
}
