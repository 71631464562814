import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { DoctorAccountService } from 'src/app/shared/service/doctors.service';
import { UsedCarEnquiriesService } from 'src/app/shared/service/usedCarEnquire.service';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
  }
}
@Component({
  selector: 'app-used-cars-enquiries',
  templateUrl: './used-cars-enquiries.component.html',
  styleUrls: ['./used-cars-enquiries.component.scss']
})
export class UsedCarsEnquiriesComponent implements OnInit {
  public transactions: any
  public arr = [];


  constructor(private usedCarEnqService: UsedCarEnquiriesService
  ) {
    usedCarEnqService.getAll()
      .subscribe((data) => {
        this.transactions = new LocalDataSource(data);
      })
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      status: {
        title: 'Status', filter: false
      },
      customer_name: {
        title: 'Customer Name', filter: false
      },
      customer_number: {
        title: 'Number', filter: false
      },
      customer_email: {
        title: 'Email', filter: false
      },
      brand_model: {
        title: 'Brand & Model',
        type: 'html',
        valuePrepareFunction: (usednew, i) => {
          return i.used_car.brand + ' ' +i.used_car.model;
        },
      },
      // doctor_account: {
      //   title: 'Customer Name',
      //   filter: false,
      //   type: 'string',
      //   valuePrepareFunction: (name) => {
      //     return name ? `${name?.name}` : '';
      //   }
      // },
      // id_number: {
      //   title: 'ID Number',
      //   filter: false,
      //   type: 'string',
      //   valuePrepareFunction: (id) => {
      //     return id ? `${id?.id_number}` : '';
      //   }
      // },
      // deal_status: {
      //   title: 'Deal Status',
      //   filter: false,
      // },
      // FINAL TODO: Change allocation to dealer staff name
      // allocation: {
      //   title: 'Allocated To', filter: false
      // },
      // viewButton: {
      //   title: 'Action',
      //   filter: false,
      //   type: 'custom',
      //   renderComponent: ButtonOneViewComponent,
      //   onComponentInitFunction(instance) {
      //     instance.customButton.subscribe(row => {
      //       alert(`THIS IS ${row.id}!`)
      //     });
      //   },
      // },
    },
  };

  ngOnInit(): void {
  }

}
