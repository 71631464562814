export interface IDoctorAccount {
    id?: number;
    pro_pic: string;
    password: string;
    name: string;
    email: string;
    surname: string;
    contact: string;
    kin: string;
    kin_contact: string;
    kin_relation: string;
    street: string;
    building: string;
    suburb: string;
    city: string;
    province: string;
    postal_code: string;
    work_street: string;
    work_building: string;
    work_suburb: string;
    work_city: string;
    work_province: string;
    work_p_code: string;
    practice_no: string;
    id_number: string;
    bank_name: string;
    bank_acc_no: string;
    bank_acc_type: string;
    bank_acc_holder: string;
    branch: string;
    branch_code: string;
    net_income: number;
    gross_income: number;
    reg_fee: number;
    accepted_terms: string;
    signature: string;
    register_date: Date;
    designation: string;
    company: string;
    company_reg: string;
    company_tel: string;
    dealer_id: number;
    device: string;
    device_type: string;
    last_login: string;
    reg_status: string;
}

export class DoctorAccount implements IDoctorAccount {
  // tslint:disable
  accepted_terms: string;
  bank_acc_holder: string;
  bank_acc_no: string;
  bank_acc_type: string;
  bank_name: string;
  branch: string;
  branch_code: string;
  building: string;
  city: string;
  company: string;
  company_reg: string;
  company_tel: string;
  contact: string;
  dealer_id: number;
  designation: string;
  device: string;
  device_type: string;
  email: string;
  gross_income: number;
  id_number: string;
  kin: string;
  kin_contact: string;
  kin_relation: string;
  last_login: string;
  name: string;
  net_income: number;
  password: string;
  postal_code: string;
  practice_no: string;
  pro_pic: string;
  province: string;
  reg_fee: number;
  reg_status: string;
  register_date: Date;
  signature: string;
  street: string;
  suburb: string;
  surname: string;
  work_building: string;
  work_city: string;
  work_p_code: string;
  work_province: string;
  work_street: string;
  work_suburb: string;
}
