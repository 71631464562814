import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, first } from 'rxjs/operators';
import { ImageService } from 'src/app/shared/service/image.service';
import { UsedCarService } from 'src/app/shared/service/usedCars.service';
import { NewCarService} from 'src/app/shared/service/newCars.service';
import { AircraftService } from 'src/app/shared/service/aircrafts.service';
import { HouseService } from 'src/app/shared/service/houses.service';
import { JewelleryService } from 'src/app/shared/service/jewellery.service';
import { YachtService } from 'src/app/shared/service/yacht.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  public usedCarForm: FormGroup;
  public newCarForm: FormGroup;
  public newAircraftForm: FormGroup;
  public houseForm: FormGroup;
  public jewelleryForm: FormGroup;
  public newStockForm: FormGroup;
  public yachtForm: FormGroup;
  public auctionForm: FormGroup;

  public counter: number = 1;

  public productType: string;
  public addMode!: boolean;
  public productId!: string;

  public header;

  public url = [
    {
      img: "assets/images/uploadIcon.png",
    },
    {
      img: "assets/images/uploadIcon.png",
    },
    {
      img: "assets/images/uploadIcon.png",
    },
    {
      img: "assets/images/uploadIcon.png",
    },
    {
      img: "assets/images/uploadIcon.png",
    }];

  imageSrc;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public imageService: ImageService,
    public usedCarService: UsedCarService,
    public newCarService: NewCarService,
    public aircraftService: AircraftService,
    public houseService: HouseService,
    public jewelleryService: JewelleryService,
    public yachtService: YachtService,

  ) {
    this.productType = this.route.snapshot.queryParams.type;
    this.productId = this.route.snapshot.queryParams.id;
    this.addMode = !this.productId;

    switch (this.productType) {
      case 'usedcar':
        this.header = 'Used Car';
        this.usedCarFormBuilder();
        break;
      case 'newcar':
        this.header = 'New Car';
        this.newCarFormBuilder();
        break;
      case 'aircraft':
        this.header = 'New Aircraft';
        this.newAircraftFormBuilder();
        break;
      case 'houses':
        this.header = 'New House';
        this.houseFormBuilder();
        break;
      case 'jewellery':
        this.header = 'New Jewellery';
        this.jewelleryFormBuilder();
        break;
      case "yacht":
        this.header = "New Yacht";
        this.yachtFormBuilder();
        break;
      case "auction":
        this.header = "Auction";
        break;
      case 'newstock':
        this.header = 'Stock Item';
        break;
      default:
        break;
    }



    // TODO: FORMBUILDER BASED OFF PRODUCT TYPE


    if (!this.addMode) {
      // TODO: EVERY EDIT MODE
      switch (this.productType) {
        case 'usedcar':
          this.usedCarService.getById(this.productId).subscribe(res => {
            this.usedCarForm.patchValue(res);
            for (let i = 0; i < this.url.length; i++) {
              this.url[i].img = res.media[i] ? res.media[i].medium.url : this.url[i].img;
            }
            this.imageSrc = this.url[0].img;
          },
            err => console.log(err),
            () => {
            });
          break;
        case 'newcar':
        this.newCarService.getById(this.productId).subscribe(res => {
          this.newCarForm.patchValue(res);
          for (let i = 0; i < this.url.length; i++) {
            this.url[i].img = res.media[i] ? res.media[i].medium.url : this.url[i].img;
          }
          this.imageSrc = this.url[0].img;
        },
          err => console.log(err),
          () => {
          });
        break;
        case 'aircraft':
        this.aircraftService.getById(this.productId).subscribe(res => {
          this.newAircraftForm.patchValue(res);
          for (let i = 0; i < this.url.length; i++) {
            this.url[i].img = res.media[i] ? res.media[i].medium.url : this.url[i].img;
          }
          this.imageSrc = this.url[0].img;
        },
          err => console.log(err),
          () => {
          });
        break;
        case 'houses':
        this.houseService.getById(this.productId).subscribe(res => {
          this.houseForm.patchValue(res);
          for (let i = 0; i < this.url.length; i++) {
            this.url[i].img = res.media[i] ? res.media[i].medium.url : this.url[i].img;
          }
          this.imageSrc = this.url[0].img;
        },
          err => console.log(err),
          () => {
          });
        break;
        case 'jewellery': // TODO
        this.jewelleryService.getById(this.productId).subscribe(res => {
          this.jewelleryForm.patchValue(res);
          for (let i = 0; i < this.url.length; i++) {
            this.url[i].img = res.media[i] ? res.media[i].medium.url : this.url[i].img;
          }
          this.imageSrc = this.url[0].img;
        },
          err => console.log(err),
          () => {
          });
          break;
        case "yacht": //TODO
        this.yachtService.getById(this.productId).subscribe(res => {
          this.yachtForm.patchValue(res);
          for (let i = 0; i < this.url.length; i++) {
            this.url[i].img = res.media[i] ? res.media[i].medium.url : this.url[i].img;
          };
          this.imageSrc = this.url[0].img;
        },
          err => console.log(err),
          () => {
          });
          break;
        // case "auction":
        //   this.header = "Auction";
        //   break;
        // case "newstock":
        //   this.header = "Stock Item";
        //   break;
        default:
          break;
      }
    }
  }

  get f() { return this.usedCarForm.controls; }

  selectedFiles = [];

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }
  // FileUpload
  readUrl(event: any, i) {

    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    let mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.selectedFiles.push(event.target.files);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    };
  }
  usedCarFormBuilder() {
    this.usedCarForm = this.fb.group({
      brand: [''],
      model: [''],
      year: [''],
      color: [''],
      kilos: [''],
      kilowatts: ['0'],
      engine_size: [''],
      transmission: [''],
      fuel: [''],
      doors: [''],
      type: [''],
      service_history: [''],
      vin: [''],
      price: [''],
      installment: [''],
      term: [''],
      description: [''],
      dealer: [''],
      // timestamp: [Date()],
      status: ['Available'],
      name: [''],
    });
  }
  newCarFormBuilder() {
    this.newCarForm = this.fb.group({
      brand: [''],
      model: [''],
      year: [''],
      color: [''],
      kilos: [''],
      kilowatts: ['0'],
      engine_size: [''],
      transmission: [''],
      fuel: [''],
      doors: [''],
      type: [''],
      service_history: [''],
      vin: [''],
      price: [''],
      installment: [''],
      term: [''],
      description: [''],
      dealer: [''],
      status: ['Available'],
      name: [''],
    });
  }
  newAircraftFormBuilder() {
    this.newAircraftForm = this.fb.group({
      category: [''],
      engine_size: [''],
      model: [''],
      pax: [''],
      cabin_features: [''],
      restroom: [''],
      true_airspeed: [''],
      ifr_range: [''],
      endurance: [''],
      crew: [''],
      communications: [''],
      registration: [''],
      yom: [''],
      insurance_cover: [''],
      dealer_id: [''],
      description: [''],
      price: [''],
      media: [''],
      status: ['Available'],
    });
  }
  houseFormBuilder() {
    this.houseForm = this.fb.group({
      title: [''],
      area: [''],
      city: [''],
      people: [''],
      bedrooms: [''],
      bathrooms: [''],
      price: [''],
      description: [''],
      category: [''],
      dealer_id: [''],
      quantity: [''],
      status: ['Available'],
    });
  };
  
  yachtFormBuilder() {
    this.yachtForm = this.fb.group({
      model: [''], 
      description: [''], 
      video: [''], 
      length_overall: [''], 
      beam: [''], 
      draft: [''], 
      displacement: [''], 
      fuel_capacity: [''], 
      water_capacity: [''], 
      hot_water_calorifier: [''], 
      black_water_tank: [''], 
      engines: [''], 
      dealer_id: [''], 
      status: ['Available'],
    });
  }
  jewelleryFormBuilder() {
    this.jewelleryForm = this.fb.group({
      title: [''],
      code: [''],
      price: [''],
      description: [''],
      category: [''],
      dealer_id: [''],
      status: ['Available'],
    });
  }

  async addForm() {
    switch (this.productType) {
      case 'usedcar':
        await this.usedCarService.create(this.usedCarForm.value)
          .then(formResult => {
            this.imageUpload(formResult.id);
          });
        break;
      case 'newcar':
        await this.newCarService.create(this.newCarForm.value)
        .then(formResult => {
          this.imageUpload(formResult.id);
        });
        break;
      case 'aircraft':
        await this.aircraftService.create(this.newAircraftForm.value)
        .then(formResult => {
          this.imageUpload(formResult.id);
        });
        break;
      case 'houses':
        await this.houseService.create(this.houseForm.value)
        .then(formResult => {
          this.imageUpload(formResult.id);
        });
        break;
      case 'jewellery':
        await this.jewelleryService.create(this.jewelleryForm.value)
        .then(formResult => {
          this.imageUpload(formResult.id);
        });
        break;
      case "yacht":
        await this.yachtService.create(this.yachtForm.value)
        .then(formResult => {
          this.imageUpload(formResult.id);
        })
        break;
      case "auction":
        this.header = "Auction";
        break;
      case 'newstock':
        this.header = 'Stock Item';
        break;
      default:
        break;
    }

  }

  updateForm() {
    switch (this.productType) {
      case 'usedcar':
        this.usedCarService.update(this.productId, this.usedCarForm.value)
          .subscribe(formResult => {
            // this.imageUpload(formResult.id);
            this.entityRouter();
          });
        break;
      case 'newcar':
        this.newCarService.update(this.productId, this.newCarForm.value)
          .subscribe(formResult => {
            // this.imageUpload(formResult.id);
            this.entityRouter();
          });
        break;
      case 'aircraft':
        this.aircraftService.update(this.productId, this.newAircraftForm.value)
          .subscribe(formResult => {
            // this.imageUpload(formResult.id);
            this.entityRouter();
          });
        break;
      case 'houses':
        this.houseService.update(this.productId, this.houseForm.value)
          .subscribe(formResult => {
            // this.imageUpload(formResult.id);
            this.entityRouter();
          });
        break;
      case "jewellery":
        this.jewelleryService.update(this.productId, this.jewelleryForm.value)
          .subscribe(formResult => {
            // this.imageUpload(formResult.id);
            this.entityRouter();
          });
        break;
      case "yacht":
        this.jewelleryService.update(this.productId, this.yachtForm.value)
          .subscribe(formResult => {
            // this.imageUpload(formResult.id);
            this.entityRouter();
          });
        break;
      case "auction":
        this.header = "Auction";
        break;
      case 'newstock':
        this.header = 'Stock Item';
        break;
      default:
        break;
    }
  }

  async imageUpload(formId) {
    const linkArray = [];
    if(formId){
      for (const x of this.selectedFiles) {
        const i = this.selectedFiles.indexOf(x);
        await this.imageService.uploadMediaPromise(x[0], this.productType)
          .then(res => {
            res.sequence = i;
            linkArray.push(res);
            this.imageService.mediaEntityLink(formId, linkArray[i], this.productType)
              .then(mediaResponse => {
                }, err => { alert(err.error); },
              );
          }, err => { console.log(err); }
          );
      }
      console.log('linkArray: ', linkArray);
      this.entityRouter();
    } else {
      alert('Pictures not uploaded, please contact tech support')
    }
  }

  entityRouter() {
    switch (this.productType) {
      case 'usedcar':
        this.router.navigate(['used-cars/used-cars']);
        break;
      case 'newcar':
        this.router.navigate(['new-cars/new-cars']);
        break;
      case 'aircraft':
        this.router.navigate(['aircrafts/aircrafts']);
        break;
      case 'houses':
        this.router.navigate(['houses/houses']);
        break;
      case 'jewellery':
        this.router.navigate(['jewellery/jewellery']);
        break;
      case "yacht":
        this.router.navigate(['yachts/yachts']);
        break;
      case "auction":
        this.router.navigate(['auction/auction']);
        break;
      case 'newstock':
        this.router.navigate(['dealer/stock-sheet']);
        break;
      default:
        break;
    }
  }
  discard() {
    this.entityRouter();
  }

  ngOnInit() {
  }

}
