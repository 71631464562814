import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NavService } from '../../service/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  logo: any;
  manager!: boolean; 
  admin!: boolean; 


  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,
    public AuthService: AuthenticationService ) {
     this.manager = JSON.parse(localStorage.getItem('currentUser')).designation == 'Manager';
     this.admin = JSON.parse(localStorage.getItem('currentUser')).designation == 'Admin'
    }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit() { 
    this.AuthService.getDealerAccount()
      .then(res => {
        this.logo = res.media[0] ? res.media[0]?.medium?.url : 'assets/images/dashboard/man.png';
      });
  }
  
  logout(){
    this.AuthService.logout();
  }
}
