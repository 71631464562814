<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <form class="needs-validation">
                <div class="col-8">
                    <div class="row">
                        <div class="">
                            <button class="btn btn-primary" type="button">Book Test Drive</button>
                            <button class="btn btn-primary m-l-10" type="button">Get A Quote</button>
                            <button class="btn btn-primary m-l-10" type="button">Book Service</button>
                            <button class="btn btn-primary m-l-10" type="button">Book Fault</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->