import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TestDriveBooking } from '../interfaces/TestDriveBooking';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestDriveBookingService {

  private apiServer = environment.appEngineURL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private httpClient: HttpClient) { }

  create(testDriveBooking: TestDriveBooking): Observable<TestDriveBooking> {
    return this.httpClient.post<TestDriveBooking>(this.apiServer + '/testDriveBookings/', JSON.stringify(testDriveBooking), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }  
  getById(id): Observable<TestDriveBooking> {
    return this.httpClient.get<TestDriveBooking>(this.apiServer + '/testDriveBookings/' + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getByDealerId(id): Observable<TestDriveBooking> {
    return this.httpClient.get<TestDriveBooking>(this.apiServer + '/testDriveBookings/' + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getAll(): Observable<TestDriveBooking[]> {
    return this.httpClient.get<TestDriveBooking[]>(this.apiServer + '/testDriveBookings/')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id, product): Observable<TestDriveBooking> {
    return this.httpClient.put<TestDriveBooking>(this.apiServer + '/testDriveBookings/' + id, JSON.stringify(product), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    return this.httpClient.delete<TestDriveBooking>(this.apiServer + '/testDriveBookings/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  errorHandler(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}