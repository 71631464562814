import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;

  public firstName;
  public occupation;
  public department;
  public designation;
  public userName;
  public dealership;
  public email;
  public profilePicture;



  constructor(private formBuilder: FormBuilder) {
    this.createAccountForm();
    this.createPermissionForm();
    this.firstName = JSON.parse(localStorage.getItem('currentUser')).firstName;
    this.department = JSON.parse(localStorage.getItem('currentUser')).department;
    this.occupation = JSON.parse(localStorage.getItem('currentUser')).occupation;
    this.designation = JSON.parse(localStorage.getItem('currentUser')).designation;
    this.userName = JSON.parse(localStorage.getItem('currentUser')).username;
    this.email = JSON.parse(localStorage.getItem('currentUser')).email;
    this.dealership = JSON.parse(localStorage.getItem('dealerAccount')).dealer_name;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      username: [''],
      firstname: [''],
      lastname: [''],
      email: [''],
      password: [''],
      confirmPwd: ['']
    })
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() { }

}
