import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { VehicleService } from 'src/app/shared/service/vehicles.service';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">View</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
  }
}
@Component({
  selector: 'app-customer-vehicles',
  templateUrl: './customer-vehicles.component.html',
  styleUrls: ['./customer-vehicles.component.scss']
})
export class CustomerVehiclesComponent implements OnInit {

  transactions: any;
  source: any;


  constructor(private vehicleService: VehicleService) {
    const dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    vehicleService.getByDealerId(dealerId)
    .subscribe((data) => {
      this.transactions = new LocalDataSource(data);
    });
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      delivery_status: {
        name: 'Action',
        title: '<i class="ion-document" title="YourAction"></i>'
      },
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      make: {
        title: 'Make', filter: false
      },
      model: {
        title: 'Model', filter: false
      },
      reg: {
        title: 'Registration', filter: false
      },
      vin: {
        title: 'Vin Number', filter: false,
        type: 'html',
      },
      // TODO: CHANGE BOTH COLUMNS TO ACTION BUTTONS
      viewButton: {
        title: 'Action',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            alert(`THIS IS ${row.id}!`);
          });
        },
      },
    },
  };

  ngOnInit(): void {
  }

  onSearchVin(query: string = '') {
    if (query === '') {
      this.transactions.setFilter([]);
      return;
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'vin',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  onSearchReg(query: string = '') {
    if (query === '') {
      this.transactions.setFilter([]);
      return ;
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'reg',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

}
