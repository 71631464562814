<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5 *ngIf="addMode && !view">Register A New Customer</h5>
            <h5 *ngIf="!addMode && !view">Edit Customer</h5>
            <h5 *ngIf="view">View Customer</h5>
        </div>
        <div class="card-body">
            <div class="col-8">
                <form [formGroup]="generalForm">
                    <section>
                        <h4>Personal Details</h4>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label>Name</mat-label>
                                <input formControlName="name" [readonly]="view" matInput placeholder="First Name">
                              <mat-error *ngIf="formErrors.name" >{{formErrors?.name}}</mat-error>
                              </mat-form-field>
                              <mat-form-field class="col form-group">
                                <mat-label>Surname</mat-label>
                                <input formControlName="surname" [readonly]="view" matInput placeholder="Last Name">
                                <mat-error *ngIf="formErrors.surname" >{{formErrors?.surname}}</mat-error>
                              </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Contact</mat-label>
                                <input formControlName="contact" [readonly]="view" matInput placeholder="Contact">
                              <mat-error *ngIf="formErrors.contact" >{{formErrors?.contact}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Email</mat-label>
                                <input formControlName="email" [readonly]="view" matInput placeholder="Email">
                              <mat-error *ngIf="formErrors.email" >{{formErrors?.email}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    ID Number</mat-label>
                                <input formControlName="id_number" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.id_number" >{{formErrors?.id_number}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>
                    <section>
                        <h4>Residential Address</h4>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Street</mat-label>
                                <input formControlName="street" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.street" >{{formErrors?.street}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Building</mat-label>
                                <input formControlName="building" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.building" >{{formErrors?.building}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Suburb</mat-label>
                                <input formControlName="suburb" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.suburb" >{{formErrors?.suburb}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    City</mat-label>
                                <input formControlName="city" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.city" >{{formErrors?.city}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Province</mat-label>
                                <mat-select formControlName="province">
                                    <mat-option *ngFor="let province of provinces;" [value]="province.name">
                                        {{province.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Postal Code</mat-label>
                                <input formControlName="postal_code" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.province" >{{formErrors?.province}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>

                    <section>
                        <h4>Work Address</h4>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Street</mat-label>
                                <input formControlName="work_street" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.work_street" >{{formErrors?.work_street}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Building</mat-label>
                                <input formControlName="work_building" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.work_building" >{{formErrors?.work_building}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Suburb</mat-label>
                                <input formControlName="work_suburb" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.work_suburb" >{{formErrors?.work_suburb}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    City</mat-label>
                                <input formControlName="work_city" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.work_city" >{{formErrors?.work_city}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Province</mat-label>
                                <mat-select formControlName="work_province">
                                    <mat-option *ngFor="let province of provinces;" [value]="province.name">
                                        {{province.name}}
                                    </mat-option>
                                </mat-select>
                              <mat-error *ngIf="formErrors.work_province" >{{formErrors?.work_province}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Postal Code</mat-label>
                                <input formControlName="work_p_code" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.work_p_code" >{{formErrors?.work_p_code}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>
                    <section>
                        <h4>Next Of Kin</h4>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Name And Surname</mat-label>
                                <input formControlName="kin" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.kin" >{{formErrors?.kin}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Relation</mat-label>
                                <input formControlName="kin_relation" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.kin_relation" >{{formErrors?.kin_relation}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Contact</mat-label>
                                <input formControlName="kin_contact" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.kin_contact" >{{formErrors?.kin_contact}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>
                    <section>
                        <h4>Income</h4>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Before Deductions</mat-label>
                                <input formControlName="gross_income" [readonly]="view" matInput placeholder=""   >
                              <mat-error *ngIf="formErrors.gross_income" >{{formErrors?.gross_income}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    After Deductions</mat-label>
                                <input formControlName="net_income" [readonly]="view" matInput placeholder="" >
                              <mat-error *ngIf="formErrors.net_income" >{{formErrors?.net_income}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>
                    <section>
                        <h4>Designation</h4>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Position</mat-label>
                                <!-- TODO: Make mat-select, Appear Drop Down Dynamically -->
                                <mat-select formControlName="designation">
                                    <mat-option *ngFor="let designation of designations; let i = index" [value]="designation.id">
                                        {{designation.name}}
                                    </mat-option>
                                </mat-select>
                              <mat-error *ngIf="formErrors.designation" >{{formErrors?.designation}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>
                    <section>
                        <h4>Vehicle</h4>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Add Vehicle?</mat-label>
                                <mat-select formControlName="add_vehicle" (valueChange)="addVehicleOption($event)">
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="Yes">Yes</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </section>
                    <section *ngIf="showVehicleDetails">
                        <h5>Add Vehicle Details</h5>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Make</mat-label>
                                <mat-select formControlName="make" (valueChange)="updateVehicleModels($event)">
                                    <mat-option *ngFor="let item of vehicleMake" [value]="item.value">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="vehicle_model" class="mr-2">
                                    Model</mat-label>
                                <mat-select formControlName="model" id="vehicle_model">
                                    <mat-option *ngFor="let item of vehicleModel" [value]="item.value">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Year</mat-label>
                                <input formControlName="year" [readonly]="view" matInput placeholder=""   >
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Kilometers</mat-label>
                                <input formControlName="kilos" [readonly]="view" matInput placeholder="" >
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Vin Number</mat-label>
                                <input formControlName="vin" [readonly]="view" matInput placeholder=""   >
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Colour</mat-label>
                                <input formControlName="color" [readonly]="view" matInput placeholder="" >
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Registration</mat-label>
                                <input formControlName="reg" [readonly]="view" matInput placeholder=""   >
                            </mat-form-field>
                            <mat-form-field class="col form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Engine Number</mat-label>
                                <input formControlName="engine_no" [readonly]="view" matInput placeholder="" >
                            </mat-form-field>
                        </div>
                      <div class="row">
                        <mat-label for="validationCustom0" class="mr-2">
                          Finance Status</mat-label>
                        <mat-radio-group formControlName="finance" (change)="vehicleFinanceChange($event)">
                          <mat-radio-button value="Financed">Financed</mat-radio-button> &nbsp;&nbsp;
                          <mat-radio-button value="Paid Up">Paid Up</mat-radio-button>
                        </mat-radio-group>
                        <mat-form-field class="col form-group" [hidden]="hideFinanceRadioBtn">
                          <mat-label for="validationCustom0" class="mr-2">
                            Bank Financing Vehicle</mat-label>
                          <input formControlName="finance_bank" [readonly]="view" matInput placeholder="" >
                        </mat-form-field>
                      </div>
                    </section>
                    <section>
                        <h4>User Device</h4>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                     Device Type</mat-label>
                                    <mat-select formControlName="device_type" >
                                        <mat-option *ngFor="let device of devices; let i = index" [value]="device.id">
                                            {{device.name}}
                                        </mat-option>
                                    </mat-select>
                              <mat-error *ngIf="formErrors.device_type" >{{formErrors?.device_type}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>
                    <section *ngIf="!view">
                        <h4 *ngIf="addMode">Create Login Password</h4>
                        <div *ngIf="!addMode">
                            <h4>Change Password</h4>
                            <p>Leave blank to keep the same password</p>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Password</mat-label>
                                <input [readonly]="view" matInput formControlName="password" type="password" >
                              <mat-error *ngIf="formErrors.password" >{{formErrors?.password}}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-6 form-group">
                                <mat-label for="validationCustom0" class="mr-2">
                                    Confirm Password</mat-label>
                                <input [readonly]="view" formControlName="verify_password" matInput type="password">
                              <mat-error *ngIf="formErrors.verify_password" >{{formErrors?.verify_password}}</mat-error>
                            </mat-form-field>
                        </div>
                    </section>

                </form>
            </div>
            <div class="pull-right" *ngIf="!view">
                <button type="button" class="btn btn-primary" (click)="registerCustomer()" *ngIf="addMode">Register Customer</button>
                <button type="button" class="btn btn-primary" (click)="updateCustomer($event)" *ngIf="!addMode">Save Customer</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
