import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UsedCar } from '../interfaces/UsedCar';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NewCar } from '../interfaces/NewCar';

@Injectable({
  providedIn: 'root'
})
export class UsedCarService {

  private apiServer = environment.appEngineURL;
  public token = JSON.parse(localStorage.getItem('currentUser')).token;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`
    })
  };
  constructor(private httpClient: HttpClient, private router: Router) { }

  create(usedCar: UsedCar): Promise<any> {
    const dealer_id = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    usedCar.dealer = dealer_id;
    return new Promise((resolve, reject) => {
      this.httpClient.post<UsedCar>(this.apiServer + '/usedCars/', JSON.stringify(usedCar), this.httpOptions)
        .subscribe(response => {
          resolve(response);
        },
          error => reject(error));
    });
  }
  // create(usedCar: UsedCar): Observable<any> {
  //   const dealer_id = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
  //   usedCar.dealer = dealer_id;
  //   return this.httpClient.post<UsedCar>(this.apiServer + '/usedCars/', JSON.stringify(usedCar), this.httpOptions)
  //     .pipe(
  //       catchError(this.errorHandler)
  //     );
  // }
  
  getById(id): Observable<any> {
    return this.httpClient.get<UsedCar>(this.apiServer + '/usedCars/' + id)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getByDealerId(id): Observable<UsedCar> {
    return this.httpClient.get<UsedCar>(this.apiServer + '/usedCars/' + id, this.httpOptions)
      .pipe(map((response: any) => response.json()));
  }

  getAll(): Observable<UsedCar[]> {
    return this.httpClient.get<UsedCar[]>(this.apiServer + '/usedCars/', this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  carSearch(search): Observable<NewCar[]> {
    return this.httpClient.get<NewCar[]>(this.apiServer + '/usedCars/search/' + search, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  update(id, product): Observable<UsedCar> {
    return this.httpClient.put<UsedCar>(this.apiServer + '/usedCars/' + id, JSON.stringify(product), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  delete(id): Observable<any> {
    return this.httpClient.delete<any>(this.apiServer + '/usedCars/' + id, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  markAsSold(id): Observable<any> {
    const soldObj = {
      status: 'Sold'
    };
    return this.httpClient.put<any>(this.apiServer + '/usedCars/' + id, JSON.stringify(soldObj), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
