import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { FormService } from 'src/app/_services/form.service';
import { DealerService } from "src/app/shared/service/dealer.service";
import { DryService } from 'src/app/shared/service/dry.service';
import { ImageService } from 'src/app/shared/service/image.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-create-dealer-account',
  templateUrl: './create-dealer-account.component.html',
  styleUrls: ['./create-dealer-account.component.scss']
})
export class CreateDealerAccountComponent implements OnInit {

  public signupForm: FormGroup;


  public firstName;
  public occupation;
  public department;
  public designation;
  public userName;
  public dealer_id;
  public dealer_name;
  public email;
  public profilePicture;

  public dealerId;
  public view!: boolean;
  public addMode!: boolean;


  public formErrors = {
    dealer_name: "",
    username: "",
    brand: "",
    password: "",
    verify_password: "",
    email: "",
    parts_email: "",
    service_email: "",
    new_sales_email: "",
    used_sales_email: "",
    finance_email: "",
    name: "",
    contact: "",
    last_login: "",
    logo: "",
    street: "",
    suburb: "",
    city: "",
    registration_num: "",
    service_repair_booking_clerk: "",
  };

  public url = [
    {
      img: "assets/images/dashboard/man.png",
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dealerService: DealerService,
    public router: Router,
    private route: ActivatedRoute,
    private formService: FormService,
    private matDialog: MatDialog,
    private dryService: DryService,
    public imageService: ImageService,

  ) {
    this.dealerId = this.route.snapshot.queryParams.dealerId;
    this.addMode = !this.dealerId;

    this.createSignupForm();
  }

  private get password() {
    const ctrl = this.signupForm.get('password');
    return ctrl ? ctrl.value : null;
  }
  private get verifyPassword() {
    const ctrl = this.signupForm.get('verify_password');
    return ctrl ? ctrl.value : null;
  }
  createSignupForm() {
    this.signupForm = this.formBuilder.group({
      dealer_name: ['', Validators.required],
      username: ['', Validators.required],
      brand: ['', Validators.required],
      password: ['', [Validators.minLength(6), this.addMode ? Validators.required : Validators.nullValidator]],
      verify_password: ['', [Validators.minLength(6), this.addMode ? Validators.required : Validators.nullValidator]],
      email: ['', Validators.required],
      parts_email: ['', Validators.required],
      service_email: ['', Validators.required],
      new_sales_email: ['', Validators.required],
      used_sales_email: ['', Validators.required],
      finance_email: ['', Validators.required],
      name: ['', Validators.required],
      contact: ['', Validators.required],
      last_login: [new Date()],
      logo: ['assets/images/user.png'],
      street: ['', Validators.required],
      suburb: ['', Validators.required],
      city: ['', Validators.required],
      registration_num: ['', Validators.required],
      service_repair_booking_clerk: ['', Validators.required],
    });

    this.signupForm.get('verify_password').setValidators([Validators.minLength(6),
    this.addMode ? Validators.required : Validators.nullValidator,
    this.formService.customValidator('passwordsMustMatch', () => {
      return this.password !== this.verifyPassword;
    })
    ]);

    this.signupForm.valueChanges.subscribe(() => {
      this.formErrors = this.formService.validateForm(this.signupForm, this.formErrors);
    });
    if (!this.addMode) {
      this.dealerService.getById(this.dealerId)
        .subscribe((res) => {
          const result = Object.assign({}, res);
          this.url[0].img = res.media[0] ? res.media[0]?.medium?.url : 'assets/images/dashboard/man.png';
          // result.province = this.titleCase.transform(res.province);
          // result.work_province = this.titleCase.transform(res.work_province);
          this.signupForm.patchValue(result);
        });
    }
  }

  selectedFiles = [];
  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.selectedFiles.push(event.target.files)
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }

  registerDealerAccount() {
    if (this.signupForm.valid) {
      const data = Object.assign({}, this.signupForm.value);
      if (data.verify_password) {
        delete data.verify_password;
      }
      this.dealerService.create(data)
        .subscribe((formResult) => {
          this.imageUpload(formResult.id);
        },
          () => {
            this.dryService.presentToast('Dealer Account Registration failed.');
          });
    } else {
      this.signupForm.markAsDirty();
      this.signupForm.markAllAsTouched();
      this.formErrors = this.formService.validateForm(this.signupForm, this.formErrors);
      this.dryService.presentToast('Some form fields are invalid.');
    }
  }
  updateDealerAccount() {
    if (this.signupForm.valid) {
      const data = Object.assign({}, this.signupForm.value);
      if (data.verify_password) {
        delete data.verify_password;
      }
      this.dealerService.create(data)
        .subscribe((formResult) => {
          this.imageUpload(formResult.id);
        },
          () => {
            this.dryService.presentToast('Dealer Account Registration failed.');
          });
    } else {
      this.signupForm.markAsDirty();
      this.signupForm.markAllAsTouched();
      this.formErrors = this.formService.validateForm(this.signupForm, this.formErrors);
      this.dryService.presentToast('Some form fields are invalid.');
    }
  }

  confirmationRouter() {
    this.dryService.presentToast('Record created successfully');
    // this.signupForm.reset();
    this.router.navigate(['settings/dealeraccounts']);
  }

  async imageUpload(formId) {
    const linkArray = [];
    const productType = 'dealerAccount';

    // TODO: TUMI
    for (const x of this.selectedFiles) {
      const i = this.selectedFiles.indexOf(x);
      await this.imageService.uploadMediaPromise(x[0], productType)
        .then(res => {
          res.sequence = i;
          linkArray.push(res);
          this.imageService.mediaEntityLink(formId, linkArray[i], productType)
            .then(mediaResponse => {
            }, err => { alert(err.error); },
            );
        }, err => { console.log(err); }
        );
    }
    console.log('linkArray: ', linkArray);
    this.confirmationRouter();
  }

  ngOnInit() { }

}
