import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewCarsEnquiriesComponent } from './new-cars-enquiries/new-cars-enquiries.component';
import { NewCarsComponent } from './new-cars/new-cars.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'new-cars',
        component: NewCarsComponent,
        data: {
          title: "New Cars",
          breadcrumb: "New Cars"
        }
      },
      {
        path: 'new-cars-enquiries',
        component: NewCarsEnquiriesComponent,
        data: {
          title: "New Cars Enquiries",
          breadcrumb: "New Cars Enquiries"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class NewCarsRoutingModule { }
