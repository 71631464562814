import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactComponent } from './transact.component';
import { BookTestDriveComponent } from './book-test-drive/book-test-drive.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { BookServiceComponent } from './book-service/book-service.component';
import { BookFaultComponent } from './book-fault/book-fault.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'transact',
        component: TransactComponent,
        data: {
          title: "Transact",
          breadcrumb: "Transact"
        }
      },
      {
        path: 'book-test-drive',
        component: BookTestDriveComponent,
        data: {
          title: "Book A Test Drive",
          breadcrumb: "Book A Test Drive"
        }
      },
      {
        path: 'get-quote',
        component: GetAQuoteComponent,
        data: {
          title: "Get A Quote",
          breadcrumb: "Get A Quote"
        }
      },
      {
        path: 'book-service',
        component: BookServiceComponent,
        data: {
          title: "Book Service",
          breadcrumb: "Book Service"
        }
      },
      {
        path: 'book-fault',
        component: BookFaultComponent,
        data: {
          title: "Book Fault",
          breadcrumb: "Book Fault"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactRoutingModule { }
