import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { RepairBookingService } from 'src/app/shared/service/repairBookings.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'app-book-service',
  templateUrl: './book-service.component.html',
  styleUrls: ['./book-service.component.scss']
})
export class BookServiceComponent implements OnInit {

  public transactions: any


  constructor(private bookingService: RepairBookingService) {
    bookingService.getAll()
    .subscribe((data) => {
      this.transactions = new LocalDataSource(data);
    })
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      date: {
        name: 'amountaaaaa',
        title: '<i class="ion-document" title="YourAction"></i>'
      },
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      name: {
        title: 'Name', filter: false
      },
      mobile: {
        title: 'mobile', filter: false
      },
      vin: {
        title: 'Vin Number', filter: false
      },
      book_time: {
        title: 'Booking Time', filter: false,
        type: 'html',
      },
      // TODO: CHANGE COLUMNS TO ACTION BUTTONS
      delivery_status: {
        title: 'Action', filter: false
      },
      amount: {
        title: 'Action', filter: false
      }
    },
  };

  ngOnInit(): void {
  }
  onSearchStatus(query: string = '') {
    if (query === '') {
            
      this.transactions.setFilter([]);
      return ;
      
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false); 
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}
