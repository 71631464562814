<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>New Cars</h5>
        </div>
        <div class="card-body">
            <section>
                <form class="needs-validation">
                <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="validationCustom0" class="mr-2">
                                Status</label>
                            <Select #search type="text" class="form-control" (change)="onSearchStatus(search.value)">
                                <option value="" selected>All</option>
                                <option value="">All</option>
                                <option value="available">Available</option>
                                <option value="sold">Sold</option>
                            </Select>
                        </div>
                        <div class="m-t-20 pull-right">
                            <button class="btn btn-primary m-l-50 m-b-10" type="button" (click)="addNewCar()">Add A New Car</button>
                        </div>
                    </div>
                </form>
            </section>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->