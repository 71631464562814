import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { JewelleryService } from 'src/app/shared/service/jewellery.service'
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { DryService } from 'src/app/shared/service/dry.service';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  constructor(private router: Router) { }

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    // TODO: EDIT PRODUCT ADD / EDIT COMPONENT FOR USED / NEW CAR
    this.router.navigate(['products/physical/add-product'], { queryParams: { type: 'jewellery', id: this.rowData.id } });

  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Delete</button>
  `,
})
export class ButtonTwoViewComponent implements ViewCell, OnInit {
  constructor(private jewelleryService: JewelleryService,) { }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.jewelleryService.delete(this.rowData.id).subscribe(res => {
      this.jewelleryService.reloadCurrentRoute();
    });
    // TODO: DELETE BUTTON, Add Confirm alert, refresh and make post delete by ID
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'sold-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Sold</button>
  `,
})
export class ButtonThreeViewComponent implements ViewCell, OnInit {
  constructor(private jewelleryService: JewelleryService,) { }

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.jewelleryService.markAsSold(this.rowData.id).subscribe(res => {
      this.jewelleryService.reloadCurrentRoute();
    });
    // TODO: SOLD BUTTON, Add Confirm alert, refresh and make post sold/update by ID
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'app-jewellery',
  templateUrl: './jewellery.component.html',
  styleUrls: ['./jewellery.component.scss']
})
export class JewelleryComponent implements OnInit {
  public jewellery: any
  public newCars = [];
  error: any;
  loading: boolean;

  constructor(
    private jewelleryService: JewelleryService,
    private router: Router,
    private dryService: DryService
  ) {
    // TODO: Change TransactionDB to Used Cars DB
    // this.jewellery = jewelleryDB.data;
    jewelleryService.getAll()
      .subscribe((data) => {
        this.jewellery = new LocalDataSource(data);
      });
  }

  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      media: {
        title: 'Image',
        type: 'html',
        valuePrepareFunction: (mediaCol) => {
          const picture = mediaCol[0] ? mediaCol[0].medium.url : "assets/images/uploadIcon.png"
          return `<img width="50px" src="${picture}" />`;
        },
      },
      title: {
        title: 'Brand', filter: false
      },
      price: {
        title: 'Price', filter: false
      },
      category: {
        title: 'Category', filter: false
      },
      code: {
        title: 'Code', filter: false
      },
      status: {
        title: 'Status', filter: false,
      },
      editButton: {
        title: 'Edit',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
        },
      },
      deleteButton: {
        title: 'Delete',
        filter: false,
        type: 'custom',
        renderComponent: ButtonTwoViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            this.dryService.presentToast('Car deleted successfully');
          });
        },
      },
      soldButton: {
        title: 'Mark As Sold',
        filter: false,
        type: 'custom',
        renderComponent: ButtonThreeViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            this.dryService.presentToast('Car has been marked as sold successfully');
          });
        },
      },
    },
  };

  ngOnInit(): void {
  }

  onSearchStatus(query: string = '') {
    if (query === '') {

      this.jewellery.setFilter([]);
      return;

    }
    this.jewellery.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  addJewellery() {
    this.router.navigate(['products/physical/add-product'], { queryParams: { type: 'jewellery' } });
  }
}