import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { TestDriveBookingService } from 'src/app/shared/service/testDriveBookings.service';
import { Router } from '@angular/router';

import { transactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  constructor(private router: Router) { }

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    // TODO: EDIT PRODUCT ADD / EDIT COMPONENT FOR USED / NEW CAR
    // this.router.navigate(['products/physical/add-product'], { queryParams: { type: 'usedcar', customerId: this.rowData.id } });

  }
}
@Component({
  selector: 'app-book-test-drive',
  templateUrl: './book-test-drive.component.html',
  styleUrls: ['./book-test-drive.component.scss']
})
export class BookTestDriveComponent implements OnInit {
  
  public transactions: any


  constructor(private testDriveService: TestDriveBookingService) {
    // TODO: Change TransactionDB to My ClIENTS DB
    testDriveService.getAll()
    .subscribe((data) => {
      this.transactions = new LocalDataSource(data);
    })
  }
  public settings = {
    hideSubHeader: true,
    actions: {
      date: {
        name: 'amountaaaaa',
        title: '<i class="ion-document" title="YourAction"></i>'
      },
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      model: {
        title: 'Model', filter: false
      },
      mobile: {
        title: 'Contact', filter: false
      },
      id_number: {
        title: 'ID Number', filter: false
      },
      book_date: {
        title: 'Book Date', filter: false,
        type: 'html',
      },
      // TODO: CHANGE COLUMNS TO ACTION BUTTONS
      book_location: {
        title: 'book_location', filter: false
      },
      editButton: {
        title: 'Edit',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            // alert(`THIS IS ${row.id}!`)
          });
        },
      },
    },
  };

  ngOnInit(): void {
  }

  onSearchStatus(query: string = '') {
    if (query === '') {
            
      this.transactions.setFilter([]);
      return ;
      
  }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false); 
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }
}
