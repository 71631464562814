<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Dealer Accounts</h5>
        </div>
        <div class="card-body">
            <section>
                <form class="needs-validation">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="username" class="mr-2">
                                Search</label>
                            <input required=""  type="text" class="form-control"
                                placeholder="Search" id="username">
                        </div>
                        <div class="m-t-20 pull-right">
                            <button class="btn btn-primary m-l-50 m-b-10" type="button" [routerLink]="'/settings/dealeraccount/create'">Add New Dealer</button>
                        </div>
                    </div>
                </form>
            </section>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->