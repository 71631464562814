<!-- Container-fluid starts-->
<!-- TODO: CONNECT DATA - EDIT PROFILE -->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img [src]="profilePicture ? profilePicture : url[0].img" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                    </div>
                    <br>
                    <div class="align-self-center text-center">
                        <h5 class="f-w-600 mb-0">Upload Dealer Picture</h5>
                        <br>
                        <!-- <div class="col-xl-3 xl-50 col-sm-6 col-3">
                            <ul class="file-upload-product">
                                <li *ngFor="let item of url; let i=index">
                                    <div class="box-input-file">
                                        <input class="upload" type="file" multiple (change)="readUrl($event)">
                                        <img class="imgUpload" alt="" [src]="item.img">
                                    </div>
                                </li>
                            </ul>
                        </div>   -->
                        <!-- <div id="hide">
                            <button type="file" class="btn btn-secondary box-input-file">
                                <app-feather-icons [icon]="'upload-cloud'" class=""></app-feather-icons>
                                <input class="upload" type="file" (change)="readUrl($event, 0)">
                            </button>
                        </div> -->
                        <div id="upload_button">
                            <label>
                              <input type="file" class="upload" (change)="readUrl($event, 0)">
                              <app-feather-icons [icon]="'upload-cloud'" class="btn btn-primary"></app-feather-icons>
                            </label>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card tab2-card">
                <div class="card-body">
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab title="Account">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="signupForm" class="needs-validation user-add" novalida>
                                        <h4>Create a dealer account</h4>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>Dealer Name</mat-label>
                                                <input formControlName="dealer_name" [readonly]="view" matInput placeholder="Dealer Name ">
                                              <mat-error *ngIf="formErrors.dealer_name" >{{formErrors?.dealer_name}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Username</mat-label>
                                                <input formControlName="username" [readonly]="view" matInput placeholder="Username">
                                                <mat-error *ngIf="formErrors.username" >{{formErrors?.username}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Registration Number</mat-label>
                                                <input formControlName="registration_num" [readonly]="view" matInput placeholder="Registration Number">
                                                <mat-error *ngIf="formErrors.registration_num" >{{formErrors?.registration_num}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>Brand</mat-label>
                                                <input formControlName="brand" [readonly]="view" matInput placeholder="Brand">
                                              <mat-error *ngIf="formErrors.brand" >{{formErrors?.brand}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Email</mat-label>
                                                <input formControlName="email" [readonly]="view" matInput placeholder="Email">
                                                <mat-error *ngIf="formErrors.email" >{{formErrors?.email}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>Name</mat-label>
                                                <input formControlName="name" [readonly]="view" matInput placeholder="Name">
                                              <mat-error *ngIf="formErrors.name" >{{formErrors?.name}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Contact</mat-label>
                                                <input formControlName="contact" [readonly]="view" matInput placeholder="Contact">
                                                <mat-error *ngIf="formErrors.contact" >{{formErrors?.contact}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>Parts Email</mat-label>
                                                <input formControlName="parts_email" [readonly]="view" matInput placeholder="Parts Email">
                                              <mat-error *ngIf="formErrors.parts_email" >{{formErrors?.parts_email}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Service Email</mat-label>
                                                <input formControlName="service_email" [readonly]="view" matInput placeholder="Service Email">
                                                <mat-error *ngIf="formErrors.service_email" >{{formErrors?.service_email}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>New Sales Email</mat-label>
                                                <input formControlName="new_sales_email" [readonly]="view" matInput placeholder="New Sales Email">
                                              <mat-error *ngIf="formErrors.new_sales_email" >{{formErrors?.new_sales_email}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Used Sales Email</mat-label>
                                                <input formControlName="used_sales_email" [readonly]="view" matInput placeholder="Used Sales Email">
                                                <mat-error *ngIf="formErrors.used_sales_email" >{{formErrors?.used_sales_email}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>Finance Email</mat-label>
                                                <input formControlName="finance_email" [readonly]="view" matInput placeholder="Finance Email">
                                              <mat-error *ngIf="formErrors.finance_email" >{{formErrors?.finance_email}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Service Repair Booking Clerk</mat-label>
                                                <input formControlName="service_repair_booking_clerk" [readonly]="view" matInput placeholder="Service Repair Booking Clerk">
                                                <mat-error *ngIf="formErrors.service_repair_booking_clerk" >{{formErrors?.service_repair_booking_clerk}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="col form-group">
                                                <mat-label>Street</mat-label>
                                                <input formControlName="street" [readonly]="view" matInput placeholder="Street">
                                              <mat-error *ngIf="formErrors.street" >{{formErrors?.street}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>Suburb</mat-label>
                                                <input formControlName="suburb" [readonly]="view" matInput placeholder="Suburb">
                                                <mat-error *ngIf="formErrors.suburb" >{{formErrors?.suburb}}</mat-error>
                                              </mat-form-field>
                                              <mat-form-field class="col form-group">
                                                <mat-label>City</mat-label>
                                                <input formControlName="city" [readonly]="view" matInput placeholder="City">
                                                <mat-error *ngIf="formErrors.city" >{{formErrors?.city}}</mat-error>
                                              </mat-form-field>
                                        </div>
                                        <section *ngIf="!view">
                                            <h4 *ngIf="addMode">Create Login Password</h4>
                                            <div *ngIf="!addMode">
                                                <h4>Change Password</h4>
                                                <p>Leave blank to keep the same password</p>
                                            </div>
                                            <div class="row">
                                                <mat-form-field class="col-md-6 form-group">
                                                    <mat-label for="validationCustom0" class="mr-2">
                                                        Password</mat-label>
                                                    <input [readonly]="view" matInput formControlName="password" type="password" >
                                                  <mat-error *ngIf="formErrors.password" >{{formErrors?.password}}</mat-error>
                                                </mat-form-field>
                                                <mat-form-field class="col-md-6 form-group">
                                                    <mat-label for="validationCustom0" class="mr-2">
                                                        Confirm Password</mat-label>
                                                    <input [readonly]="view" formControlName="verify_password" matInput type="password">
                                                  <mat-error *ngIf="formErrors.verify_password" >{{formErrors?.verify_password}}</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </section>
                                    </form>
                                </div>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-secondary m-r-10">Cancel</button>
                                    <button type="button" class="btn btn-primary"
                                        (click)="registerDealerAccount()" *ngIf="addMode">Create</button>
                                    <button type="button" class="btn btn-primary"
                                        (click)="updateDealerAccount()" *ngIf="!addMode">Save</button>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->