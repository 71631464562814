import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NeedsAnalysisComponent } from './needs-analysis.component';
import { MyClientsComponent } from './my-clients/my-clients.component';
import { AllClientsComponent } from './all-clients/all-clients.component';
import { NeedsAnalysisStatsTargetsComponent } from './needs-analysis-stats-targets/needs-analysis-stats-targets.component';
import { NeedsAnalysisViewTargetsComponent } from './needs-analysis-view-targets/needs-analysis-view-targets.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'needs-analysis',
        component: NeedsAnalysisComponent,
        data: {
          title: "Needs Analysis",
          breadcrumb: "Needs Analysis"
        }
      },
      {
        path: 'my-clients',
        component: MyClientsComponent,
        data: {
          title: "My Clients",
          breadcrumb: "My Clients"
        }
      },
      {
        path: 'all-clients',
        component: AllClientsComponent,
        data: {
          title: "All Clients",
          breadcrumb: "All Clients"
        }
      },
      {
        path: 'stats',
        component: NeedsAnalysisStatsTargetsComponent,
        data: {
          title: "Stats & Targets",
          breadcrumb: "Stats & Targets"
        }
      },
      {
        path: 'view-targets',
        component: NeedsAnalysisViewTargetsComponent,
        data: {
          title: "View Targets",
          breadcrumb: "View Targets"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NeedsAnalysisRoutingModule { }
