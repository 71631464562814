import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactComponent } from './transact.component';
import { BookTestDriveComponent } from './book-test-drive/book-test-drive.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { BookServiceComponent } from './book-service/book-service.component';
import { BookFaultComponent } from './book-fault/book-fault.component';
import { TransactRoutingModule } from './transact-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';



@NgModule({
  declarations: [TransactComponent, BookTestDriveComponent, GetAQuoteComponent, BookServiceComponent, BookFaultComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    TransactRoutingModule
  ]
})
export class TransactModule { }
