import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IDoctorAccount } from 'src/app/shared/interfaces/DoctorsAccount';
import { DoctorAccountService } from 'src/app/shared/service/doctors.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';
import { FormService } from 'src/app/_services/form.service';

@Component({
  selector: 'app-needs-analysis',
  templateUrl: './needs-analysis.component.html',
  styleUrls: ['./needs-analysis.component.scss']
})
export class NeedsAnalysisComponent implements OnInit {
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  needsForm: FormGroup;

  public transactions = [];

  public userDetails: IDoctorAccount = {
    id: null,
    pro_pic: '',
    password: '',
    name: '',
    email: '',
    surname: '',
    contact: '',
    kin: '',
    kin_contact: '',
    kin_relation: '',
    street: '',
    building: '',
    suburb: '',
    city: '',
    province: '',
    postal_code: '',
    work_street: '',
    work_building: '',
    work_suburb: '',
    work_city: '',
    work_province: '',
    work_p_code: '',
    practice_no: '',
    id_number: '',
    bank_name: '',
    bank_acc_no: '',
    bank_acc_type: '',
    bank_acc_holder: '',
    branch: '',
    branch_code: '',
    net_income: null,
    gross_income: null,
    reg_fee: null,
    accepted_terms: '',
    signature: '',
    register_date: null,
    designation: '',
    company: '',
    company_reg: '',
    company_tel: '',
    dealer_id: null,
    device: '',
    device_type: '',
    last_login: '',
    reg_status: '',
  };
  public searchParam = '';
  public dealerId: string;


  constructor(private formService: FormService, private formBuilder: FormBuilder,
              private titleCase: TitleCasePipe,
              private doctorService: DoctorAccountService) {
    // TODO: Change TransactionDB to Customer Accounts DB
    this.dealerId = JSON.parse(localStorage.getItem('currentUser')).dealer_id;
    this.transactions = transactionsDB.data;
  }

  ngOnInit(): void {
  }

  private createNeedsAnalysisForm() {
    this.needsForm = this.formBuilder.group({
      make: ['', [Validators.required]],
      model: ['', [Validators.required]],
      colour: ['', []],
      colour2: ['', []],
      colour3: ['', []],
      manufacture_type: ['', [Validators.required]],
      park_distance: ['', [Validators.required]],
      sports: ['', [Validators.required]],
      sunroof: ['', [Validators.required]],
      smash_protect: ['', [Validators.required]],
      features: ['', []],
      engine: ['', [Validators.required]],
      transmission: ['', [Validators.required]],
      fuel: ['', [Validators.required]],
      deposit: ['', [Validators.required]],
      deposit_amnt: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      installment: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      trading: ['', [Validators.required]],
      vehicle_trd: ['', []],
      trade_year: ['', []],
      trade_vin: ['', []],
      trade_engine_no: ['', []],
      trade_engine_size: ['', []],
      trade_fuel_type: ['', []],
      trade_transmission: ['', []],
      trade_type: ['', []],
      trade_colour: ['', []],
      trade_kilos: ['', []],
      trade_finance: ['', []],
      trade_notes: ['', []],
      user: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      user_idnum: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      date: ['', [Validators.required]],
      dealer_id: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      deal_status: ['', []],
      allocation: ['', []],
      rating: ['', []],
      review: ['', []],
      notes: ['', []],
      next_appointment: ['', []],
      app_time: ['', []],
    });
  }

  searchUser(idNumber: string) {
    this.doctorService.searchByIdNumber(idNumber)
      .subscribe((res) => {
        const result = Object.assign({}, res);
        result.province = this.titleCase.transform(res.province);
        result.work_province = this.titleCase.transform(res.work_province);
        result.password = '';
        this.userDetails = result;
        // this.nes.patchValue(result);
      });
  }
}
