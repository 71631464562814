import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NewCarService } from 'src/app/shared/service/newCars.service'
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { DryService } from 'src/app/shared/service/dry.service';

@Component({
  selector: 'edit-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Edit</button>
  `,
})
export class ButtonOneViewComponent implements ViewCell, OnInit {
  constructor(private router: Router) { }

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.customButton.emit(this.rowData);
    // TODO: EDIT PRODUCT ADD / EDIT COMPONENT FOR USED / NEW CAR
    this.router.navigate(['products/physical/add-product'], { queryParams: { type: 'newcar', id: this.rowData.id } });

  }
}

@Component({
  selector: 'delete-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Delete</button>
  `,
})
export class ButtonTwoViewComponent implements ViewCell, OnInit {
  constructor(private newCarService: NewCarService,) { }
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.newCarService.delete(this.rowData.id).subscribe(res => {
      this.newCarService.reloadCurrentRoute();
    });
    // TODO: DELETE BUTTON, Add Confirm alert, refresh and make post delete by ID
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'sold-button-view',
  template: `
    <button class="btn btn-primary" (click)="onClick()">Sold</button>
  `,
})
export class ButtonThreeViewComponent implements ViewCell, OnInit {
  constructor(private newCarService: NewCarService,) { }

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() customButton: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.newCarService.markAsSold(this.rowData.id).subscribe(res => {
      this.newCarService.reloadCurrentRoute();
    });
    // TODO: SOLD BUTTON, Add Confirm alert, refresh and make post sold/update by ID
    this.customButton.emit(this.rowData);
  }
}

@Component({
  selector: 'app-new-cars',
  templateUrl: './new-cars.component.html',
  styleUrls: ['./new-cars.component.scss']
})
export class NewCarsComponent implements OnInit {
  public transactions: any
  public newCars = [];
  error: any;
  loading: boolean;

  constructor(
    private newCarService: NewCarService,
    private router: Router,
    private dryService: DryService
  ) {
    // TODO: Change TransactionDB to Used Cars DB
    // this.transactions = transactionsDB.data;
    newCarService.getAll()
      .subscribe((data) => {
        this.transactions = new LocalDataSource(data);
      });
  }

  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      media: {
        title: 'Image',
        type: 'html',
        valuePrepareFunction: (mediaCol) => {
          const picture = mediaCol[0] ? mediaCol[0].medium.url : "assets/images/uploadIcon.png"
          return `<img width="50px" src="${picture}" />`;
        },
      },
      brand: {
        title: 'Make', filter: false
      },
      model: {
        title: 'Model', filter: false
      },
      year: {
        title: 'Year', filter: false
      },
      vin: {
        title: 'Vin', filter: false
      },
      status: {
        title: 'Status', filter: false,
      },
      editButton: {
        title: 'Edit',
        filter: false,
        type: 'custom',
        renderComponent: ButtonOneViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
          });
        },
      },
      deleteButton: {
        title: 'Delete',
        filter: false,
        type: 'custom',
        renderComponent: ButtonTwoViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            this.dryService.presentToast('Car deleted successfully');
          });
        },
      },
      soldButton: {
        title: 'Mark As Sold',
        filter: false,
        type: 'custom',
        renderComponent: ButtonThreeViewComponent,
        onComponentInitFunction(instance) {
          instance.customButton.subscribe(row => {
            this.dryService.presentToast('Car has been marked as sold successfully');
          });
        },
      },
    },
  };

  ngOnInit(): void {
    // this.newCarService.getById('15')
    //   .pipe(first())
    //   .subscribe(
    //     (data: any) => {
    //       this.newCars.push(data);
    //       console.log(this.newCars);
    //     },
    //     error => {
    //       console.log(error);
    //       this.error = error;
    //     });
  }

  onSearchStatus(query: string = '') {
    if (query === '') {

      this.transactions.setFilter([]);
      return;

    }
    this.transactions.setFilter([
      // fields we want to include in the search
      {
        field: 'status',
        search: query
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  addNewCar() {
    this.router.navigate(['products/physical/add-product'], { queryParams: { type: 'newcar' } });
  }
}
